define("apollo/pods/container/model", ["exports", "ember-data", "apollo/pods/vehicle/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ownerName: _emberData.default.attr('string'),
    trailer: _emberData.default.belongsTo('trailer', {
      async: true
    }),
    carType: _emberData.default.attr('string'),
    controllingNumber: _emberData.default.attr('string'),
    mpk: _emberData.default.attr('string'),
    bulwarks: _emberData.default.attr('boolean'),
    downAirConnection: _emberData.default.attr('boolean'),
    validations: {
      registrationNumber: {
        presence: {
          message: 'blank'
        },
        length: {
          minimum: {
            value: 3,
            message: 'tooShort'
          },
          maximum: {
            value: 40,
            message: 'tooLong'
          }
        }
      }
    }
  });

  _exports.default = _default;
});