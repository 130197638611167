define("apollo/translations/pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "address": {
      "addNewAddress": "Dodaj nowy adres",
      "businessHours": "Godziny otwarcia",
      "city": "Miasto",
      "confirmMarkAsHeadquarter": "Na pewno chcesz oznaczyć adres jako siedzibę firmy?",
      "coordinates": "Współrzędne",
      "country": "Kraj",
      "deleteAddress": "Na pewno chcesz usunąć adres?",
      "errors": {
        "city": {
          "tooShort": "Nazwa miasta powinna się składać z co najmniej trzech znaków."
        },
        "closingTimeString": {
          "beforeOpening": "Godzina zamknięcia musi być późniejsza niż godzina otwarcia.",
          "null": "Proszę podać godzinę zamknięcia.",
          "wrongFormat": "Proszę podać godzinę zamknięcia w formacie HH:mm, np. 16:30."
        },
        "country": {
          "blank": "Proszę podać kraj."
        },
        "lat": {
          "incorrectNumber": "Niepoprawne współrzędne"
        },
        "lon": {
          "incorrectNumber": "Niepoprawne współrzędne"
        },
        "openingTimeString": {
          "null": "Proszę podać godzinę otwarcia.",
          "wrongFormat": "Proszę podać godzinę otwarcia w formacie HH:mm, np. 08:00."
        },
        "postal": {
          "tooShort": "Kod pocztowy powinien się składać z co najmniej trzech znaków.",
          "wrongFormatForPL": "Proszę podać kod pocztowy w formacie 01-234."
        },
        "street": {
          "tooShort": "Nazwa ulicy powinna się składać z co najmniej trzech znaków."
        }
      },
      "isHeadquarter": "Siedziba firmy",
      "label": "Adres",
      "mark": "Oznacz",
      "markAsHeadquarter": "Oznacz jako siedziba",
      "postal": "Kod pocztowy",
      "recipient": "Adresat",
      "recipientPlaceholder": "Zacznij pisać, by wyszukać adresata..",
      "selectCompany": "Najpierw wybierz klienta",
      "street": "Ulica"
    },
    "advice": {
      "additionalDriver": "Podwójna obsada",
      "carrierAdvice": "Awizacja przewoźnika",
      "carrierChosen": "Wybrany przewoźnik",
      "costsFirstStepDescription": "<b>km</b> (myjnia - załadunek)",
      "costsSecondStepDescription": "<b>km</b> (załadunek - rozładunek)",
      "costsThirdStepDescription": "<b>km</b> (rozładunek - myjnia)",
      "creator": "Twórca awizacji",
      "driver": "Kierowca",
      "emptyKilometers": "Podjazd na załadunek w km",
      "emptyKilometersTitle": "Podaj wartość pustych kilometrów",
      "emptyKm": "Podjazd",
      "errors": {
        "carRegistrationNumber": {
          "blank": "Proszę podać numer rejestracyjny ciągnika"
        },
        "driver": {
          "blank": "Proszę podać kierowcę"
        },
        "driverMobilePhoneNumber": {
          "blank": "Proszę podać numer telefonu kierowcy"
        },
        "driverName": {
          "blank": "Proszę podać imię i nazwisko kierowcy"
        },
        "emptyKilometers": {
          "incorrectNumber": "Proszę podać poprawną liczbę"
        },
        "rodoAccepted": {
          "blank": "Dane muszą być przetwarzane legalnie"
        },
        "trailer": {
          "blank": "Proszę podać przyczepę"
        },
        "truck": {
          "blank": "Proszę podać ciężarówkę"
        },
        "vehicle": {
          "blank": "Proszę podać pojazd"
        }
      },
      "intermodalSettlement": "Rozliczenie intermodalne",
      "intermodalSettlementHint": "W przypadku, gdy opcja \"Rozliczenie intermodalne\" nie zostanie wybrana, to obiektem kosztowym jest wartość pola \"Obiekt kosztowy\" podana w szczegółach danego ciągnika.\n\n W przypadku, gdy opcja \"Rozliczenie intermodalne\" zostanie wybrana, to obiekt kosztowy będzie określony przez:\n - pole MPK z wybranego kontenera do awizacji (jeżeli w polu naczepa będzie sam kontener bez podwozia),\n - pole MPK z przypisanego kontenera do naczepy z awizacji (jeżeli w polu naczepa zostanie wybrane podwozie z przypisanym kontenerem),\n - w przypadku braku kontenera w awizacji obiekt kosztowy nie jest określany.",
      "ownAdvice": "Awizacja własna",
      "rodo": "Wprowadzane dane mogą być legalnie przetwarzane przez Logintegra sp. z o.o.",
      "trailer": "Naczepa",
      "truck": "Ciągnik",
      "washChosen": "Wybrana myjnia"
    },
    "afterRegistration": {
      "invitationButton": "Lista zaproszeń",
      "label": "Witamy!",
      "title": "Jesteś na dobrej drodze do pożegnania kolejek!"
    },
    "auth": {
      "alreadyRegistered": "Masz już konto?",
      "changePassword": {
        "false": "Zmień hasło",
        "true": "Nie zmieniaj hasła"
      },
      "confirmPassword": "Potwierdź hasło",
      "description": "Platforma logistyczna",
      "emailPlaceholder": "Wpisz adres e-mail",
      "fail": "Logowanie nie powiodło się",
      "footer": "Przygotowany przez",
      "invalidLoginOrPassword": "Login lub hasło są niepoprawne.",
      "login": "Login",
      "loginPlaceholder": "Wpisz login",
      "logout": "Wyloguj",
      "newPassword": "Hasło",
      "noLoginAndPassword": "Proszę podać login i hasło.",
      "notRegistered": "Nie masz konta?",
      "oldPassword": "Dotychczasowe hasło",
      "password": "Hasło",
      "passwordPlaceholder": "Wpisz hasło",
      "passwordRemindedMessage": "Nowe hasło dla użytkownika {username} zostało wysłane na adres {email}.",
      "remind": "Przypomnij",
      "remindEmail": "Podaj adres e-mail powiązany z Twoim kontem, na który wyślemy tymczasowe hasło",
      "remindFail": "Przypominanie hasła nie powiodło się",
      "remindHeader": "Przypominanie hasła",
      "remindLink": "Zapomniałeś hasła?",
      "remindingInProgress": "Wysyłamy e-mail",
      "repeatNewPassword": "Powtórzone hasło",
      "serverError": "Serwer nie odpowiada. Spróbuj ponownie za kilka minut lub skontaktuj się z info@logintegra.com.",
      "username": "Nazwa użytkownika"
    },
    "cannotAdviceDueToInvalidResourceDates": {
      "hint": "Prosimy o zaktualizowanie dat ważności w szczegółach zasobu.",
      "info": "Nie możesz stworzyć awizacji ze względu na terminy ważności dokumentów zasobów:"
    },
    "checkpoints": {
      "beingProcessedHint": "Status jest obecnie przetwarzany",
      "cannotConfirmHint": "Nie masz uprawnień do potwierdzenia tego statusu",
      "confirm": "Potwierdź",
      "confirmExecutionOnTime": "Wykonano {executionDate} o {executionTime}",
      "confirmStatus": "Potwierdź status",
      "confirmTitle": "Potwierdź {stateName} transportu nr {identifier}",
      "confirmedMessage": "potw. {date} przez {person}",
      "empty": "Brak statusów",
      "errors": {
        "CheckpointNotFound": "Wybrany etap nie istnieje, lub został już potwierdzony w aplikacji PCC. Prosimy o sprawdzenie lub odświeżenie strony.",
        "CheckpointNotFoundOrDeletedByAnotherTransactionError": "Nie możemy potwierdzić wybranego etapu. Zlecenie zostało zaktualizowane przez innego użytkownika. Prosimy spróbować ponownie.",
        "CheckpointsSynchronizationError": "Wybrany etap został już potwierdzony w aplikacji PCC. Prosimy o sprawdzenie lub odświeżenie strony.",
        "SapException": "Wysyłanie kosztu do SAP nie powiodło się",
        "canNotConfirmCheckpoint": "Nie możesz jeszcze potwierdzić tego etapu. Samochód prawdopodobnie nie opuścił miejsca załadunku lub nie wysłałeś jeszcze awizacji do klienta. Spróbuj ponownie później.",
        "executionTimeBeforePreviousCheckpoint": "Czas wykonania etapu musi być późniejszy niż czas potwierdzenia poprzedniego etapu ({minDate})",
        "executionTimeInFuture": "Czas wykonania etapu musi się znajdować w przeszłości.",
        "invalidData": "Niepoprawne dane!",
        "orderNotFound": "Próba synchronizacji zlecenia zakończona niepowodzeniem: wybrane zlecenie nie istnieje w systemie PCC. Prosimy o kontakt z administratorem.",
        "reasonForTheDelay": {
          "blank": "Proszę podać powód opóźnienia."
        }
      },
      "executionTime": "Godzina wykonania",
      "noExecutionDateHint": "Przycisk będzie aktywny dopiero po zatwierdzeniu odpowiedniego statusu w zleceniu powiązanym z zamówieniem",
      "noMechanic": "Proszę najpierw wybrać mechanika",
      "noServicePlace": "Proszę najpierw wybrać miejsce serwisu",
      "noWash": "Proszę najpierw wybrać myjnię",
      "plan": "plan.",
      "processFinished": "Proces jest zakończony!",
      "processFinishedShort": "Zakończone",
      "reasonForTheDelay": "Powód opóźnienia",
      "revokeConfirm": "Na pewno chcesz cofnąć potwierdzenie tego statusu?",
      "revokeManyConfirm": "Uwaga: jako że nie jest to ostatni potwierdzony status, usuniemy także wszystkie późniejsze potwierdzenia. Na pewno chcesz to zrobić?"
    },
    "columns": {
      "advice": "Awizacja",
      "arrival": "Wjazd",
      "arrivalDate": "Data dostawy",
      "car": "Pojazd",
      "cargo": "Palety",
      "carrier": "Przewoźnik",
      "city": "Miasto",
      "collectionPlace": "Miejsce odbioru",
      "company": "Firma",
      "contact": "Kontakt",
      "costs": "Rozliczenia",
      "costsHeader": "Koszty własne",
      "country": "Kraj",
      "creationDate": "Utworzenie",
      "deliveryPlace": "Miejsce dostawy",
      "departure": "Wyjazd",
      "description": "Opis",
      "driver": "Kierowca",
      "driverPhoneNumber": "Telefon",
      "forwarder": "Spedytor",
      "forwarderGroup": "Grupa",
      "group": "Grupa",
      "hour": "Godzina",
      "identifier": "Id",
      "load": "Załadunek",
      "mechanic": "Mechanik",
      "notifications": "SMS/Email",
      "number": "Numer",
      "owner": "Właściciel",
      "phone": "Telefon",
      "product": "Produkt",
      "serviceCost": "Koszt",
      "serviceEndDate": "Koniec serwisu",
      "serviceFinishedJobs": "Prace zakończone",
      "serviceNumber": "Numer serwisu",
      "servicePlace": "Miejsce obsługi",
      "serviceRemarks": "Uwagi serwisowe",
      "serviceRequestedJobs": "Prace zlecone",
      "serviceStartDate": "Początek serwisu",
      "status": "Status",
      "supplier": "Klient",
      "tasks": {
        "Approve": "Zatwierdzone",
        "Confirm": "Potwierdzone"
      },
      "technicalEmployee": "Pracownik działu technicznego",
      "term": {
        "full": "Termolabilne",
        "short": "Term."
      },
      "timeWindowDate": "Załadunek",
      "tradesman": "Handlowiec",
      "trailer": "Naczepa",
      "truckSideNumber": "Numer boczny",
      "unload": "Rozładunek",
      "vehicle": "Pojazd",
      "wash": "Myjnia"
    },
    "common": {
      "accept": "Zaakceptuj",
      "accepted": "Zaakceptowane",
      "active": "Aktywny",
      "add": "Dodaj",
      "addFile": "Dodaj plik",
      "additionalComments": "Dodatkowe uwagi",
      "address": "Adres",
      "appVersion": "Wersja",
      "back": "Powrót",
      "browseAndUploadDocuments": "Przeglądaj i dodawaj dokumenty",
      "by": "przez",
      "byYou": "przez Ciebie",
      "cancel": "Anuluj",
      "cancelAndBack": "Anuluj i wróć",
      "check": "sprawdź",
      "clear": "Wyczyść",
      "clearFilters": "Wyczyść filtry",
      "close": "Zamknij",
      "company": "Firma",
      "confirmMessage": "Jesteś pewien?",
      "create": "Utwórz",
      "createSimilar": "Utwórz podobny",
      "dateCreated": "Data utworzenia",
      "day": "Dzień",
      "days14": "14 Dni",
      "days2": "2 Dni",
      "days4": "4 Dni",
      "daysOfWeek": {
        "1": "Pon",
        "2": "Wt",
        "3": "Śr",
        "4": "Czw",
        "5": "Pt",
        "6": "So",
        "7": "Nie",
        "label": "w dniach"
      },
      "delete": "Usuń",
      "deleteTitle": "Usuń transport (usunięty transport znajdzie się w archiwum).",
      "deletingProgress": "usuwanie",
      "desc": "Opis",
      "description": "Opis",
      "details": "szczegóły",
      "disable": "Wyłącz",
      "downloadManualHeader": "Pobierz instrukcję obsługi",
      "edit": "Edytuj",
      "email": "Adres email",
      "enable": "Włącz",
      "enterEmail": "Podaj adres email",
      "exportAsXLS": "Eksportuj do pliku XLS",
      "externalCostOrders": "{count} {count, plural, one {zamówienie} few {zamówienia} other {zamówień}}",
      "false": "Nie",
      "forDateRange": {
        "M": "miesiąca",
        "Y": "roku"
      },
      "from": "od",
      "fromCompany": "z firmy",
      "hint": "Podpowiedź",
      "identifier": "Identyfikator",
      "idx": "Indeks",
      "instructionalVideo": "Film instruktażowy",
      "interval": {
        "d": "{count} {count, plural, one {dzień} other {dni}}",
        "m": "{count} {count, plural, one {miesiąc} few {miesiące} other {miesięcy}}",
        "w": "{count} {count, plural, one {tydzień} few {tygodnie} other {tygodni}}",
        "y": "{count} {count, plural, one {rok} few {lata} other {lat}}"
      },
      "kg": "kg",
      "lastUpdated": "Data ostatniej zmiany",
      "lat": "szer",
      "less": "Mniej...",
      "loading": "Ładujemy dane...",
      "lon": "dł",
      "maxDate": "maks. data",
      "minDate": "min. data",
      "month": "Miesiąc",
      "more": "Więcej...",
      "name": "Nazwa",
      "newMessagesTitle": "Masz nieprzeczytane wiadomości.",
      "no": "Nie",
      "noAdviceAddedYet": "Nie dodano jeszcze żadnej awizacji",
      "noMatches": "Brak wyników",
      "noResults": "Brak wyników dla danego wyszukiwania",
      "notSpecified": "nie podano",
      "oldBrowser": "Używasz przestarzałej przeglądarki. Nasza aplikacja będzie lepiej działać na nowoczesnej przeglądarce, takiej jak Google Chrome, Firefox, Opera, Vivaldi, Safari, lub IE Edge.",
      "phone": "Telefon",
      "processing": "Pracujemy nad tym..",
      "recipient": "Adresat",
      "register": "Dołącz",
      "reject": "Odrzuć",
      "rejected": "Odrzucone",
      "role": "Rola",
      "save": "Zapisz",
      "saving": "Zapisywanie",
      "search": "Szukaj",
      "selectDate": "Wybierz datę",
      "send": "Wyślij",
      "sendSms": "Wyślij SMS",
      "services": "{count} {count, plural, one {naprawa} few {naprawy} other {napraw}}",
      "settings": "Ustawienia",
      "shortName": "Nazwa skrócona",
      "show": "Pokaż",
      "timeSign": {
        "after": "po",
        "before": "przed"
      },
      "timeUnit": {
        "d": "dni",
        "h": "godzin",
        "m": "minut",
        "s": "sekund"
      },
      "to": "do",
      "today": "Dziś",
      "transportDeletionDisabled": "Nie możesz już usunąć tego transportu. Skontaktuj się z administratorem.",
      "transports": "{count} {count, plural, one {transport} few {transporty} other {transportów}}",
      "true": "Tak",
      "type": "Typ",
      "unavailable": "Niedostępne",
      "update": "Aktualizuj",
      "uploadFile": "Dodaj plik",
      "value": "Wartość",
      "waiting": "Oczekuje",
      "week": "Tydzień",
      "yes": "Tak"
    },
    "company": {
      "addDriver": "Dodaj kierowcę do firmy {companyName}",
      "addEmail": "Dodaj nowy adres mailowy",
      "addVehicle": "Dodaj pojazd do firmy {companyName}",
      "address": "Adres",
      "addresses": "Adresy",
      "card": {
        "CARRIER": "Karta przewoźnika",
        "CLIENT": "Karta klienta",
        "SERVICE": "Karta serwisu",
        "WASH": "Karta myjni"
      },
      "contractorNumber": "Nr kontrahenta",
      "country": "Kraj",
      "create": {
        "CARRIER": "Utwórz przewoźnika",
        "CLIENT": "Utwórz klienta",
        "SERVICE": "Utwórz serwis",
        "WASH": "Utwórz myjnię"
      },
      "deleteConfirm": "Na pewno chcesz usunąć firmę?",
      "deleteEmail": "Usuń adres mailowy",
      "deletedMessage": "Poprawnie usunęliśmy firmę \"{companyName}\".",
      "editCompany": "Edytuj firmę",
      "emails": "Adresy mailowe",
      "employees": "Pracownicy",
      "errors": {
        "companyType": {
          "blank": "Proszę wybrać typ firmy."
        },
        "country": {
          "blank": "Proszę wybrać kraj."
        },
        "name": {
          "blank": "Proszę podać nazwę firmy.",
          "tooShort": "Nazwa firmy powinna się składać z co najmniej trzech znaków."
        },
        "shortName": {
          "blank": "Proszę podać skróconą nazwę firmy.",
          "tooShort": "Skrócona nazwa firmy powinna się składać z co najmniej dwóch znaków."
        },
        "taxIdNumber": {
          "notANumber": "Numer NIP powinien być poprawną liczbą.",
          "tooShort": "Numer NIP powinien się składać z co najmniej trzech znaków."
        }
      },
      "files": "Dokumenty",
      "integrated": {
        "CARRIER": "Zintegrowany",
        "SERVICE": "Serwis zintegrowany",
        "WASH": "Myjnia PCC Autochem"
      },
      "intermodalCarrier": "Spedycja intermodalna",
      "label": "Firma",
      "list": "Lista firm",
      "name": "Nazwa",
      "noEmployees": "Firma nie ma pracowników",
      "onlyAsCustomerOfService": "Wyłącznie jako klient serwisu",
      "openingHours": "Godziny otwarcia",
      "partnershipStatus": {
        "ACCEPTED": "Zaakceptowane",
        "NO_INVITATION": "Brak zaproszenia",
        "REJECTED": "Odrzucone",
        "WAITING": "Oczekuje",
        "label": "Status"
      },
      "paymentOptions": {
        "_15_DAYS_AFTER_ISSUING_INVOICE": "15 dni od wystawienia faktury",
        "_15_DAYS_AFTER_RECEIVING_INVOICE": "15 dni po otrzymaniu faktury",
        "_30_DAYS_AFTER_ISSUING_INVOICE": "30 dni od wystawienia faktury",
        "_30_DAYS_AFTER_RECEIVING_INVOICE": "30 dni po otrzymaniu faktury",
        "_45_DAYS_AFTER_ISSUING_INVOICE": "45 dni od wystawienia faktury",
        "_45_DAYS_AFTER_RECEIVING_INVOICE": "45 dni po otrzymaniu faktury",
        "_60_DAYS_AFTER_ISSUING_INVOICE": "60 dni od wystawienia faktury",
        "_60_DAYS_AFTER_RECEIVING_INVOICE": "60 dni po otrzymaniu faktury",
        "label": "Warunki płatności",
        "select": "Wybierz warunki płatności"
      },
      "savedMessage": "Poprawnie zapisaliśmy firmę \"{companyName}\".",
      "selectCountry": "Wybierz kraj",
      "selectDriver": "Wybierz kierowcę",
      "selectType": "Wybierz typ",
      "selectVehicle": "Wybierz pojazd",
      "shortName": "Nazwa skrócona",
      "subcontractorCode": "Kod podwykonawcy",
      "sureToDeleteEmail": "Czy na pewno chcesz usunąć ten adres email z listy?",
      "taxIdNumber": "NIP",
      "taxIdNumberHint": "W polu NIP należy wprowadzić prawidłowy numer składający się z 10 cyfr, pisanych w ciągu (bez myślników). W systemie może być zarejestrowana tylko jedna firma pod danym numerem NIP.",
      "type": {
        "CARRIER": "Przewoźnik",
        "CLIENT": "Klient",
        "FORWARDER": "Spedytor",
        "SERVICE": "Serwis",
        "SUPPLIER": "Dostawca \\\\ Odbiorca",
        "WASH": "Myjnia",
        "label": "Typ"
      },
      "updatedMessage": "Poprawnie zaktualizowaliśmy firmę \"{companyName}\"."
    },
    "company-email": {
      "errors": {
        "email": {
          "notValidEmail": "Niepoprawny adres email"
        }
      }
    },
    "container": {
      "errors": {
        "registrationNumber": {
          "blank": "Proszę podać numer kontenera",
          "tooLong": "Numer konetenera powinien składać się z maksymalnie 40 znaków",
          "tooShort": "Numer kontenera powinien składać się z co najmniej trzech znaków"
        }
      }
    },
    "controlledDateTypes": {
      "daysBeforeAdviceWarning": {
        "full": "Ile dni przed upływem terminu ważności powinno być wyświetlane ostrzeżenie w formularzu awizacji",
        "short": "Ostrzeżenie w formularzu awizacji"
      },
      "daysBeforeFirstWarning": {
        "full": "Ile dni przed upływem terminu ważności data w karcie zasobów powinna być w kolorze żółtym",
        "short": "Żółte ostrzeżenie"
      },
      "daysBeforeSecondWarning": {
        "full": "Ile dni przed upływem terminu ważności data w karcie zasobów powinna być w kolorze czerwonym",
        "short": "Czerwone ostrzeżenie"
      },
      "daysBeforeSendEmail": {
        "full": "Ile dni przed upływem terminu ważności powinien być wysyłany mail z powiadomieniem",
        "short": "Powiadomienie mailowe"
      },
      "documentNameLabel": "Nazwa dokumentu",
      "label": "Ustawienia kontrolowanych dat",
      "labelEn": "Nazwa angielska",
      "labelPl": "Nazwa polska",
      "specificClientInfo": "Blokada awizacji ze względu na upłynięcie tej daty ważności obowiązuje tylko w zleceniach, w których klientem jest firma {company}",
      "updateDateIntervalWarning": {
        "full": "Interwał określający minimalną różnicę podczas edycji daty, która powoduje wyświetlenie ostrzeżenia",
        "short": "Ostrzeżenie podczas edycji daty"
      }
    },
    "deliveries": {
      "add": "Dodaj dostawę",
      "label": "Dostawy"
    },
    "delivery": {
      "label": "Dostawa"
    },
    "documentsExpirationDate": {
      "expired": "{document}: ważność dla {resource} wygasła",
      "expiredOn": "{document}: {date} upłynął termin ważności {resource}",
      "notExists": "{document}: brak daty ważności dla {resource}",
      "warning": "{document}: ważność dla {resource} niedługo wygaśnie",
      "willExpireOn": "{document}: {date} upływa termin ważności {resource}"
    },
    "driver": {
      "active": "Kierowca aktywny",
      "adrNumber": "Numer zaświadczenia ADR",
      "card": "Karta kierowcy",
      "comment": "Dodatkowe uwagi",
      "country": "Narodowość",
      "deleteConfirm": "Na pewno chcesz usunąć kierowcę?",
      "deletedMessage": "Poprawnie usunęliśmy kierowcę \"{driverName}\".",
      "documentNumber": "Numer dokumentu",
      "driverCardNumber": "Numer karty kierowcy",
      "driverLicenceNumber": "Numer prawa jazdy",
      "errors": {
        "documentNumber": {
          "blank": "Podaj numer dokumentu",
          "tooShort": "Numer dokumentu powinien się składać z co najmniej trzech znaków."
        },
        "flTrackerId": {
          "notANumber": "Numer musi zawierać wyłącznie cyfry"
        },
        "identifier": {
          "wrongControlSum": "Podany numer nie jest poprawnym numerem PESEL",
          "wrongLength": "Numer PESEL powinien się składać z 11 znaków."
        },
        "name": {
          "blank": "Proszę podać nazwisko kierowcy.",
          "tooShort": "Nazwisko kierowcy powinno się składać z co najmniej trzech znaków."
        },
        "phoneNumber": {
          "blank": "Podaj numer telefonu",
          "tooShort": "Numer telefonu powinien się składać z co najmniej trzech znaków."
        },
        "phoneNumberPrefix": {
          "blank": "Podaj numer kierunkowy"
        },
        "rodoAccepted": {
          "blank": "Dane muszą być przetwarzane legalnie"
        }
      },
      "flTrackerId": "ID (lokalizator)",
      "identifier": "PESEL",
      "inactiveHint": "Kierowca nieaktywny",
      "intermodalCarrier": "Spedycja intermodalna",
      "name": "Nazwisko i imię",
      "new": "Nowy kierowca",
      "owner": "Właściciel",
      "phoneNumber": "Numer telefonu",
      "phoneNumberPrefix": "Numer kierunkowy",
      "savedMessage": "Poprawnie zapisaliśmy kierowcę \"{driverName}\".",
      "shortName": "Nazwa skrócona",
      "taxIdNumber": "NIP"
    },
    "external-cost": {
      "ADDITIONAL_INCOME": "Przychody dodatkowe",
      "AUTOMATIC_WASHING_COST": "Koszty myjni przypisanej do zlecenia",
      "EXTERNAL_COSTS": "Koszty zewnętrzne",
      "FREIGHT_COSTS": "Fracht",
      "approval": "Zatwierdzenie",
      "company": "Kontrahent",
      "confirmation": "Potwierdzenie",
      "controllingOrderNumber": "Numer zl. kontrol.",
      "costHasBeenSentToSapInfo": "Zamówienie związane z tym kosztem zostało zatwierdzone w tabeli zamówień i wysłane do SAP",
      "dateOfExecution": "Data wyk. usługi",
      "errors": {
        "company": {
          "blank": "Wybierz typ"
        },
        "currency": {
          "blank": "Wybierz walutę"
        },
        "externalCostType": {
          "blank": "Wybierz kontrahenta"
        },
        "price": {
          "blank": "Podaj cenę"
        }
      },
      "generateCost": {
        "AUTOMATIC_WASHING_COST": "Wygeneruj koszt myjni przypisanej do zlecenia",
        "FREIGHT_COSTS": "Wygeneruj koszt frachtu"
      },
      "messages": {
        "APPROVE_EXTERNAL_COST_ORDER": "Utworzono zamówienie",
        "CONFIRM_EXTERNAL_COST_ORDER": "Potwierdzono zamówienie",
        "DELETE_EXTERNAL_COST_ORDER": "Usunięto zamówienie"
      },
      "order": {
        "delete": "Usuń zamówienie",
        "deleted": "Zamówienie zostało usunięte",
        "deletingInProgress": "Zamówienie jest w trakcie usuwania...",
        "deletingNotPossibleConfirmedInSap": "Zamówienie zostało już potwierdzone w SAP i nie ma możliwości jego usunięcia",
        "editExecutionDate": "Edytuj datę wykonania usługi",
        "noAuthorityToDelete": "Nie masz uprawnień, żeby usunąć zamówienie",
        "sureToDeleteCost": "Czy jesteś pewny, że chcesz usunąć koszt ze zlecenia? Usunięcie kosztu spowoduje także usunięcie powiązanego zamówienia.",
        "sureToDeleteOrder": "Czy jesteś pewny, że chcesz usunąć to zamówienie? Razem z zamówieniem zostanie też usunięty koszt z transportu z sekcji Rozliczenia."
      },
      "orderNumber": "Numer zamówienia",
      "price": "Cena",
      "pzNumber": "Numer PZ",
      "table": {
        "company": "Kontrahent",
        "date": "Data",
        "price": "Cena",
        "type": "Typ"
      },
      "type": "Typ",
      "types": {
        "AUTOMATIC_WASHING": "Automatyczny koszt mycia",
        "FREEZING": "Mrożenie",
        "FREIGHT": "Fracht",
        "HEATING": "Grzanie",
        "LOAD_DELAYED": "Opóźnienie załadunku",
        "ORDER_CANCELLED": "Anulowanie zlecenia",
        "OTHER": "Inne",
        "OTHER_INCOME": "Inne",
        "UNLOAD_DELAYED": "Opóźnienie rozładunku",
        "WASHING": "Mycie"
      }
    },
    "file": {
      "desc": "Dodany {dateCreated} przez {personName}.",
      "errors": {
        "fileExists": "Plik o nazwie {fileName} już istnieje."
      }
    },
    "filterNames": {
      "ADVICE_CREATED": "Niewysłana awizacja do klienta",
      "ADVICE_SMS": "Niewysłana informacja do kierowcy",
      "AUTOMATIC_WASHING": "Automatyczny koszt mycia",
      "CONFIRMED": "Potwierdzone",
      "DELETED": "Usunięte",
      "DURING_UNLOAD": "Trwa rozładunek",
      "FINISHED": "Zakończone",
      "FREEZING": "Mrożenie",
      "FREIGHT": "Fracht",
      "FREIGHT_ORDER_NUMBER": "Niewysłane zlecenie do przewoźnika",
      "HEATING": "Grzanie",
      "INTERMODAL": "Intermodal",
      "INTERNATIONAL": "Międzynarodowa",
      "IN_PROGRESS": "W trakcie",
      "LOADED": "Załadowany",
      "NATIONAL": "Krajowa",
      "NO_ADVICE": "Brak awizacji",
      "ON_LOAD": "Na załadunku",
      "ON_THE_ROAD": "W trasie",
      "OTHER": "Inne",
      "PLANNED": "Zaplanowane",
      "READY_FOR_UNLOAD": "Gotowość do rozładunku",
      "TO_PLAN": "Do zaplanowania",
      "TO_WASH": "Do umycia",
      "UNCONFIRMED": "Niepotwierdzone",
      "UNLOADED": "Rozładowany",
      "UNSAVED": "Niezapisane",
      "WAITING_FOR_APPROVAL": "Oczekuje na zatwierdzenie",
      "WAITING_FOR_CONFIRMATION": "Oczekuje na potwierdzenie",
      "WAITING_ON_LOAD": "Oczekuje na załadunek",
      "WASH": "Myjnia",
      "WASHING": "Mycie",
      "WASH_SMS": "Niewysłana myjnia do kierowcy",
      "false": "Nie",
      "true": "Tak"
    },
    "forwarderGroup": {
      "INTERMODAL": "Intermodal",
      "INTERMODAL.abbr": "Inter.",
      "INTERNATIONAL": "Międzynarodowa",
      "INTERNATIONAL.abbr": "Międzyn.",
      "NATIONAL": "Krajowa",
      "NATIONAL.abbr": "Kraj."
    },
    "help": {
      "contactUs": "lub skontaktuj się z nami",
      "contactWithWarehouse": "Kontakt z magazynem",
      "deleteTitle": "Usuń ten transport (usunięty transport pojawi się w archiwum).",
      "newMessagesTitle": "Nieprzeczytane wiadomości: %@1.",
      "statute": "Regulamin"
    },
    "invitation": {
      "acceptedByAnotherPerson": "Zaproszenie zostało już zaakceptowane przez inną osobę z Państwa firmy.",
      "acceptedSummary": "Zaakceptowane",
      "alreadyAccepted": "To zaproszenie zostało już zaakceptowane.",
      "alreadyHandled": "Zaproszenie zostało już obsłużone",
      "createdBy": "{personName} zaprasza Cię, byś dołączył do firmy {companyName} jako {roleName}.",
      "creator": "Autor",
      "for": {
        "CARRIER": "Przewoźnika",
        "CLIENT": "Klienta",
        "JOIN_COMPANY": "Do swojej firmy",
        "SERVICE": "Serwis",
        "SUPPLIER": "Dostawcę \\\\ Odbiorcę",
        "WASH": "Myjnię"
      },
      "invite": "Zaproś użytkownika",
      "label": "Zaproszenie",
      "notFound": "Zaproszenie nie istnieje, lub straciło ważność.",
      "registeredAccount": "Zarejestrowane konto",
      "registrationComplete": "Dziękujemy. Twoje konto zostało zarejestrowane. Możesz się zalogować wykorzystując login {username}.",
      "rejectedSummary": "Odrzucone",
      "sendTo": "Wyślij zaproszenie",
      "sent": "Zaproszenie zostało wysłane na adres {email}.",
      "toPartnership": "{personName} zaprasza Cię, byś rozpoczął współpracę z firmą {companyName} jako {roleName}.",
      "type": {
        "CARRIER": "Dołącz jako przewoźnik",
        "CLIENT": "Dołącz jako klient",
        "JOIN_COMPANY": "Dołącz do firmy",
        "SERVICE": "Dołącz jako serwis",
        "SUPPLIER": "Dołącz jako dostawca \\\\ odbiorca",
        "WASH": "Dołącz jako myjnia"
      }
    },
    "invitations": {
      "emptyReceived": "Nie otrzymano jeszcze żadnego zaproszenia.",
      "emptySent": "Nie wysłano jeszcze żadnego zaproszenia.",
      "label": "Zaproszenia",
      "received": "Otrzymane",
      "sent": "Wysłane"
    },
    "leave": {
      "createTitle": "Zaplanuj urlop",
      "deleteLeave": "Czy na pewno chcesz usunąć ten urlop?",
      "next": "Najbliższy urlop",
      "plan": "Zaplanuj",
      "previous": "Poprzedni urlop",
      "startDate": "Początek urlopu",
      "stopDate": "Koniec urlopu",
      "title": "Urlop"
    },
    "log": {
      "actor": "Autor zmiany",
      "change": "Zmiana",
      "dateCreated": "Data",
      "properties": {
        "ADR": "ADR",
        "BBS_TRAINING": "Szkolenie BBS",
        "CARRIER_LICENCE": "Licencja",
        "CARRIER_LICENCE_added_file": "Dodano plik (Licencja)",
        "CARRIER_LICENCE_removed_file": "Usunieto plik (Licencja)",
        "DRIVER_CARD": "Karta kierowcy",
        "DRIVER_LICENCE": "Prawo jazdy",
        "ID_DOCUMENT": "Dokument tożsamości",
        "INSURANCE": "Ubezpieczenie",
        "LAST_REVIEW": "Przegląd rejestracyjny",
        "MEDICAL_EXAMINATION": "Badania lekarskie",
        "OCP_INSURANCE": "Ubezpieczenie OCP",
        "OCP_INSURANCE_added_file": "Dodano plik (Ubezpieczenie OCP)",
        "OCP_INSURANCE_removed_file": "Usunieto plik (Ubezpieczenie OCP)",
        "OSH_TRAINING": "Szkolenie BHP",
        "PCC_MCAA_TRAINING": "Szkolenie PCC MCAA",
        "PSYCHOTECHNICAL_EXAMINATION": "Badania psychotechniczne",
        "SUPERVISION": "Data dozoru",
        "TACHOGRAPH": "Przegląd tachografu",
        "TDT_PERMISSIONS": "Uprawnienia TDT (UNO)",
        "TDT_PERMISSIONS_NEUTRAL": "Uprawnienia TDT (neutralne)",
        "active": "Kierowca aktywny",
        "adrNumber": "Numer zaświadczenia ADR",
        "automaticConfirmationWash": "Automatyczne potwierdzenie mycia",
        "axisType": "Rodzaj osi",
        "bulwarks": "Falochrony",
        "capacity": "Pojemność",
        "carType": "Typ pojazdu",
        "carTypeGroup": "Środek transportu",
        "comment": "Dodatkowe uwagi",
        "company_added_file": "Dodano plik (Dokumenty)",
        "company_removed_file": "Usunieto plik (Dokumenty)",
        "contractorCompany": "Nazwa skrócona",
        "contractorNumber": "Numer kontrahenta",
        "controllingNumber": "Zlecenie kontrolingowe",
        "costObject": "Obiekt kosztowy",
        "country": "Narodowość",
        "dateCreated": "Data utworzenia",
        "description": "Opis",
        "documentNumber": "Numer dokumentu",
        "downAirConnection": "Podłączenie powietrza na dole",
        "driver": "Kierowca",
        "driver.taxIdNumber": "NIP",
        "driverCardNumber": "Numer karty kierowcy",
        "driverLicenceNumber": "Numer prawa jazdy",
        "earliestStepStart": "Zmiana początkowej daty urlopu",
        "euroStandard": "Norma Euro",
        "flTrackerId": "ID (lokalizator)",
        "group": "Grupa",
        "identifier": "PESEL",
        "integrated": "Zintegrowany",
        "intermodalCarrier": "Spedycja intermodalna",
        "invitation": "Zaproszenie",
        "latestStepStop": "Zmiana końcowej daty urlopu",
        "model": "Model",
        "mpk": "MPK",
        "name": "Nazwisko i imię",
        "newAddress": "Nowy adres",
        "newEmail": "Nowy adres email",
        "newLeave": "Nowy urlop",
        "numberOfCompartments": "Ilość komór",
        "numberOfManholes": "Ilość włazów",
        "oldAddress": "Stary adres",
        "onlyAsCustomerOfService": "Wyłącznie jako klient serwisu",
        "outOfCommission": "Sprzęt wyłączony z eksploatacji",
        "partnershipType": "Typ współpracy",
        "paymentOption": "Warunki płatności",
        "phoneNumber": "Numer telefonu",
        "phoneNumberPrefix": "Numer kierunkowy",
        "principalCompany": "Główna firma",
        "registrationNumber": "Numer rejestracyjny",
        "removedEmail": "Usunięty adres email",
        "removedLeave": "Usunięto urlop",
        "shortName": "Nazwa skrócona",
        "sideNumber": "Numer boczny",
        "status": "Status",
        "subcontractorCode": "Kod podwykonawcy",
        "taxIdNumber": "NIP",
        "trailer": "Naczepa",
        "valveType": "Typ zaworu",
        "yearOfProduction": "Rocznik"
      },
      "property": "Pole",
      "valueAfterChange": "Wartość pola po zmianie",
      "valueBeforeChange": "Wartość pola przed zmianą"
    },
    "notification": {
      "ADVICE_CREATED": {
        "desc": "Powiadomienie wysyłane do klienta i twórcy po zapisaniu awizacji.",
        "label": "Awizacja do klienta",
        "label.short": "Awizacja do klienta",
        "unsent": "Niewysłana awizacja do klienta"
      },
      "ADVICE_SMS": {
        "desc": "SMS do kierowcy z danymi zlecenia",
        "label": "Zlecenie do kierowcy",
        "label.short": "Zlecenie do kierowcy",
        "unsent": "Niewysłana informacja do kierowcy"
      },
      "ADVICE_UPDATED": {
        "desc": "Powiadomienie wysyłane do zainteresowanych (np. jeżeli twórcą awizacji jest dostawca\\\\odbiorca, e-mail zostanie wysłany do spedytora oraz przewoźników) po zmianie awizacji.",
        "label": "Zmiana awizacji",
        "label.short": "Zmiana awizacji"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "Powiadomienie wysyłane po zatwierdzeniu statusu {checkpointName}.",
        "label": "Zatwierdzenie statusu {checkpointName} dla transportu typu {transportTypeName}"
      },
      "FREIGHT_ORDER_NUMBER": {
        "desc": "E-mail do przewoźnika i kontrahentów o przydzielonym zleceniu",
        "label": "Zlecenie do przewoźnika",
        "label.short": "Zlecenie do przew.",
        "unsent": "Niewysłane zlecenie do przewoźnika"
      },
      "LATE_ADVICE": {
        "desc": "Powiadomienie wysyłane w przypadku opóźnienie przyjazdu kierowcy.",
        "label": "Opóźnienie awizacji",
        "label.short": "Opóźnienie awizacji"
      },
      "LATE_CARRIER_OCP": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności daty znajdującej się w karcie przewoźnika",
        "label": "Upływający termin ważności ubezpieczenia OCP"
      },
      "LATE_CONTAINER_SUPERVISION": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności daty dozoru",
        "label": "Upływający termin ważności daty dozoru kontenera"
      },
      "LATE_DOCUMENTS": {
        "desc": "Powiadomienie wysyłane, jeżeli dostawca nie doda dokumentów przewozowych w odpowiednim terminie.",
        "label": "Opóźnienie w dodaniu dokumentów"
      },
      "LATE_DRIVER_DOCUMENTS": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności daty znajdującej się w karcie kierowcy",
        "label": "Upływający termin ważności daty z karty kierowcy"
      },
      "LATE_TRAILER_ADR": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności ADR",
        "label": "Upływający termin ważności ADR naczepy"
      },
      "LATE_TRAILER_INSURANCE": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności ubezpieczenia",
        "label": "Upływający termin ważności ubezpieczenia naczepy"
      },
      "LATE_TRAILER_LAST_REVIEW": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności przeglądu",
        "label": "Upływający termin ważności przeglądu naczepy"
      },
      "LATE_TRAILER_SUPERVISION": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności daty dozoru",
        "label": "Upływający termin ważności daty dozoru naczepy"
      },
      "LATE_TRAILER_TACHOGRAPH": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności przeglądu tachografu",
        "label": "Upływający termin ważności tachografu naczepy"
      },
      "LATE_TRUCK_ADR": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności ADR",
        "label": "Upływający termin ważności ADR ciągnika"
      },
      "LATE_TRUCK_INSURANCE": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności ubezpieczenia",
        "label": "Upływający termin ważności ubezpieczenia ciągnika"
      },
      "LATE_TRUCK_LAST_REVIEW": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności przeglądu",
        "label": "Upływający termin ważności przeglądu ciągnika"
      },
      "LATE_TRUCK_TACHOGRAPH": {
        "desc": "Powiadomienie wysyłane X dni przed upływem ważności przeglądu tachografu",
        "label": "Upływający termin ważności tachografu ciągnika"
      },
      "TRANSPORT_READY_AFTER_CONFIRMATION": {
        "desc": "Powiadomienie o przyjęciu zlecenia do realizacji, wysyłane po potwierdzeniu zlecenia przez spedytora.",
        "label": "Informacja o przyjęciu zlecenia do realizacji",
        "label.short": "Przyjęcie zlecenia"
      },
      "WASH_SMS": {
        "desc": "SMS do kierowcy z danymi myjni",
        "label": "Myjnia do kierowcy",
        "label.short": "Myjnia do kierowcy",
        "unsent": "Niewysłana myjnia do kierowcy"
      },
      "checkingStatusFailed": "Sprawdzanie statusu w serwisie zewnętrznym zakończyło się niepowodzeniem. Wyświetlony status może nie być zgodny ze stanem faktycznym.",
      "confirmation": "Na pewno chcesz je wysłać?",
      "dateCreated": "Data utworzenia",
      "dateSent": "Data obsłużenia",
      "errors": {
        "SmsServerMessageIdNotFound": "Nie możemy sprawdzić statusu powiadomienia - brak id wiadomości z serwera sms",
        "TransportNotificationNotFound": "Nie znaleziono powiadomienia o podanym id",
        "WrongNotificationType": "Zły typ powiadomienia - informacje o statusie dostępne tylko dla powiadomień sms"
      },
      "label": "Powiadomienie",
      "label.EMAIL": "Powiadomienie e-mail",
      "label.SMS": "Powiadomienie SMS",
      "noReceivers": {
        "desc": "Adresaci nie mieli podanego adresu email / numeru telefonu, lub nie zaznaczyli chęci otrzymywania tego powiadomienia w ustawieniach swojego konta.",
        "label": "Powiadomienie nie zostało wysłane"
      },
      "receivers": "Adresaci",
      "roleGroups": {
        "desc": "Użytkownicy z daną rolą będą mogli otrzymywać dane powiadomienie oraz włączać/wyłączać chęć otrzymywania go w ustawieniach swojego konta.",
        "label": "Role użytkowników"
      },
      "sender": "Nadawca",
      "sent": "Wysłane",
      "status": "Status: ",
      "statusDesc": {
        "delivered": "Doręczenie sms do odbiorcy",
        "queued": "Przyjęcie sms przez serwis zewnętrzny",
        "sent": "Wysłanie sms do odbiorcy"
      },
      "statusLabel": {
        "delivered": "Doręczone",
        "in_progress": "Przetwarzane",
        "queued": "W kolejce",
        "saved": "Zapisane",
        "sent": "Wysłane",
        "undelivered": "Niedostarczone",
        "unsent": "Niewysłane"
      },
      "statusesHeader": "Statusy powiadomienia SMS nr {id}"
    },
    "notifications": {
      "label": "Powiadomienia",
      "noNotificationsForRole": "Dla wybranej roli nie ma dostępnych powiadomień",
      "selectRole": "Proszę najpierw wybrać rolę."
    },
    "order": {
      "history": "Historia zleceń"
    },
    "page": {
      "pageTitle": "Platforma logistyczna"
    },
    "partnership": {
      "errors": {
        "contractorNumber": {
          "tooShort": "Numer kontrahenta powinien się składać z co najmniej trzech znaków."
        },
        "subcontractorCode": {
          "blank": "Proszę podać kod podwykonawcy"
        }
      }
    },
    "product": {
      "errors": {
        "symbol": {
          "blank": "Proszę podać symbol"
        },
        "totalGrossTonnage": {
          "blank": "Proszę podać wagę brutto"
        }
      }
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Wprowadzone hasła muszą być identyczne.",
      "header": "Zmiana hasła logowania do systemu",
      "info": "Reset hasła jest wymagany przez politykę bezpieczeństwa systemu.",
      "info2": "Nowe hasło musi być różne od wcześniej wprowadzonego oraz powinno zawierać 8 znaków (cyfry oraz małe i wielkie litery)."
    },
    "resources": {
      "cannotChangeClientMessage": {
        "basic": "Nie można wybrać klienta {company} ze względu na przeterminowane daty zasobów:",
        "hint": "Zaktualizuj daty i ponownie wybierz klienta {company}"
      },
      "dateRange": "Zakres dat",
      "dateStatuses": {
        "INVALID": "przeterminowane",
        "RED": "czerwone",
        "VALID": "w terminie",
        "YELLOW": "żółte",
        "label": "status"
      },
      "hideInactiveDrivers": "Ukryj nieaktywnych kierowców",
      "hideIntegratedDrivers": "Ukryj kierowców przewoźników zintegrowanych",
      "hideIntegratedTrucks": "Ukryj ciągniki przewoźników zintegrowanych",
      "hideOutOfCommission": "Ukryj {vehicleType} wyłączone z użycia",
      "of": {
        "CARRIER": "przewoźnika",
        "CISTERN": "cysterny",
        "CONTAINER": "kontenera",
        "DOCUMENT": "dokumentu",
        "DRIVER": "kierowcy",
        "TRAILER": "naczepy",
        "TRUCK": "ciągnika",
        "UNDERCARRIAGE": "podwozia",
        "VEHICLE": "pojazdu"
      },
      "showAll": "Pokaż dodatkowo {vehicleType} wyłączone z użycia",
      "showAllDrivers": "Pokaż dodatkowo nieaktywnych kierowców",
      "showIntegratedDrivers": "Pokaż kierowców przewoźników zintegrowanych",
      "showIntegratedTrucks": "Pokaż ciągniki przewoźników zintegrowanych",
      "updateDateExceedIntervalWarning": {
        "basic": "Różnice między poprzednimi a nowo wybranymi datami przekraczają ustalone interwały:",
        "confirm": "Czy jesteś pewien, że chcesz zapisać zmiany?",
        "details": "{controlledDateType} - różnica przekracza interwał o wartości {interval}"
      }
    },
    "roleGroup": {
      "ADMIN": {
        "desc": "Użytkownik mający wszelkie możliwe uprawnienia. Ma dostęp do wszystkich zakładek. Może tworzyć i edytować użytkowników i firmy.",
        "label": "Admin"
      },
      "CARRIER": {
        "desc": "Przewoźnik. Może tworzyć dostawy od wielu dostawców. Widzi tylko okna czasowe, dla których jest przewoźnikiem.",
        "label": "Przewoźnik"
      },
      "CHOOSE_COMPANY": {
        "desc": "Proszę najpierw wybrać firmę."
      },
      "CLIENT": {
        "desc": "Klient. Może tworzyć zlecenia.",
        "label": "Klient"
      },
      "FORWARDER": {
        "desc": "Użytkownik mogący tworzyć i edytować dostawy oraz okna czasowe.",
        "label": "Spedytor"
      },
      "GUARD": {
        "desc": "Użytkownik ochrony. Nie może tworzyć ani edytować dostaw.",
        "label": "Ochrona"
      },
      "MANAGER": {
        "desc": "Ma uprawnienia analogiczne do spedytora, dodatkowo może zatwierdzać i usuwać zamówienia związane z kosztami zewnętrznymi",
        "label": "Kierownik"
      },
      "MECHANIC": {
        "desc": "Użytkownik przyjmujący zlecenia serwisowe - bez dostępu do systemu.",
        "label": "Mechanik"
      },
      "OBSERVER": {
        "desc": "Użytkownik mogący jedynie przeglądać dostawy.",
        "label": "Obserwator"
      },
      "SUPPLIER": {
        "desc": "Może tworzyć dostawy tylko dla swojej firmy. Nie widzi okien czasowych innych dostawców.",
        "label": "Dostawca \\\\ Odbiorca"
      },
      "TECHNICAL_EMPLOYEE": {
        "desc": "Użytkownik mogący tworzyć i edytować serwisy.",
        "label": "Pracownik działu technicznego"
      },
      "TRADESMAN": {
        "desc": "Sprzedawca.",
        "label": "Handlowiec"
      },
      "WAREHOUSEMAN": {
        "desc": "Użytkownik mający dostęp do tabeli dostaw i magazynu. Nie może tworzyć ani edytować dostaw.",
        "label": "Magazyn"
      },
      "WASH": {
        "desc": "Myjnia.",
        "label": "Myjnia"
      },
      "label": "Rola",
      "name": "Nazwa",
      "placeholder": "Wybierz rolę",
      "selectCompany": "Wybierz firmę"
    },
    "route": {
      "label": "Trasa"
    },
    "schedule": {
      "ADVICED": "Zaawizowane",
      "ASSIGNED": "Przydzielone",
      "EXTERNAL": "Firmy zewnętrzne",
      "NOTADVICED": "Niezaawizowane",
      "NOTASSIGNED": "Nieprzydzielone",
      "NOTPLANNED": "Niezaplanowane",
      "PLANNED": "Zaplanowane",
      "areYouSure": "Czy chcesz zaakaceptować?",
      "cantPlaceAdvice": "Niestety nie możemy dodać awizacji. Pojazd o numerze bocznym jest wykorzystywany w innym zleceniu.",
      "wasDropped": " został opuszczony ",
      "wasResized": " został zmieniony ",
      "week": "Tydzień"
    },
    "service": {
      "arrival": "Wjazd",
      "company": "Firma",
      "contractorCompanyPlaceholder": "Nazwa firmy",
      "costs": "Koszty",
      "createTitle": "Zaplanuj serwis",
      "departure": "Wyjazd",
      "description": "Opis prac",
      "documents": "Dokumenty",
      "errors": {
        "mileage": {
          "blank": "Proszę podać przebieg",
          "notAnInteger": "Przebieg powinien być liczbą całkowitą"
        },
        "serviceType": {
          "blank": "Proszę wybrać typ serwisu"
        },
        "workload": {
          "incorrectNumber": "Niepoprawna liczba"
        }
      },
      "finishedJobs": "Prace ukończone",
      "history": "Historia napraw/przeglądów",
      "id": "Numer serwisu",
      "internalWork": "Praca własna",
      "mechanic": "Mechanik",
      "mileage": "Przebieg [km]",
      "noJobs": "Brak zaplanowanych prac",
      "owner": "Właściciel",
      "registrationNumber": "Numer rejestracyjny",
      "requestedJobs": "Prace zaplanowane",
      "selectCompany": "Wybierz firmę",
      "selectDate": "Wybierz datę",
      "selectMechanic": "Wybierz mechanika",
      "selectOwner": "Wybierz właściciela",
      "selectType": "Wybierz typ serwisu",
      "selectVehicle": "Wybierz pojazd",
      "servicePlace": "Miejsce obsługi",
      "serviceType": "Typ serwisu",
      "startDate": "Początek naprawy",
      "statuses": "Statusy",
      "stopDate": "Koniec naprawy",
      "technicalEmployee": "Nazwisko pracownika działu technicznego",
      "title": "Serwis",
      "vehicle": "Pojazd",
      "workload": "Pracochłonność [h]"
    },
    "service-type": {
      "errors": {
        "name": {
          "blank": "Nazwa typu serwisu jest wymagana"
        }
      }
    },
    "services": {
      "add": "Zaplanuj serwis",
      "label": "Serwisy"
    },
    "settings": {
      "backToSettings": "Powrót do listy ustawień",
      "desc": {
        "ARCHIVING_AFTER_DELETE_JOB_INTERVAL": "Ile czasu po usunięciu powinniśmy przenieść transport do archiwum.",
        "ARCHIVING_JOB_INTERVAL": "Ile czasu po potwierdzeniu ostatniego statusu powinniśmy przenieść transport do archiwum.",
        "AUTOMATIC_LOAD_MORE": "Automatyczne dociąganie zleceń po przescrollowaniu do końca tabeli transportów",
        "AVERAGE_TRUCK_SPEED_IN_KMPH": "Średnia prędkość pojazdu (w km/h)",
        "CHECKPOINT_APPROVED_AFTER_SELECTING_CARRIER": "Checkpoint zatwierdzany po wyborze przewoźnika",
        "CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_MECHANIC": "Checkpoint zatwierdzany po wyborze mechanika",
        "CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_SERVICE_PLACE": "Checkpoint może być zatwierdzony po wybraniu miejsca serwisu",
        "CHECKPOINT_CAN_BE_APPROVED_AFTER_SELECTING_WASH": "Checkpoint, który może być zatwierdzony po wyborze myjni",
        "CHECKPOINT_THAT_APPROVES_ORDER": "Nazwa statusu po, którym zlecenie zostanie potwierdzone i zostanie wysłany mail do klienta",
        "DAYS_TO_RESET_PASSWORD": "Co ile dni powinien się pojawiać komunikat o konieczności resetu hasła.",
        "DEFAULT_WORKLOAD_TIME_IN_HOURS": "Domyślne godzinowe obciążenie pracą",
        "DELETE_COST_IN_SAP_ERROR_EMAIL": "Mail, na który będzie wysyłana informacja o niepowodzeniu po kolejnej nieudanej próbie usunięcia kosztu w SAP",
        "DELETE_TRANSPORT_INTERVAL": "Do jakiego momentu w stosunku do planowanej daty rozładunku powinno być możliwe usuwanie transportu.",
        "DOCUMENTS_MONITORING_INTERVAL": "Wielkość opóźnienia w dodaniu dokumentów, po którym wysłany zostanie mail do klienta.",
        "EXTERNAL_COST_ORDERS_FEATURE_ENABLED": "Włącz funkcjonalność zatwierdzania zamówień",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Link do warunków usługi.",
        "INVITATION_EXPIRATION_INTERVAL": "Okres ważności zaproszenia.",
        "LOGO_ON_LOGIN_PAGE": "Link do logo, które jest wyświetlane na stronie logowania",
        "MINIMAL_INTERMODAL_EXPECTED_MARGIN": "Minimalna oczekiwana marża intermodal",
        "MINIMAL_INTERNATIONAL_EXPECTED_MARGIN": "Minimalna oczekiwana marża międzynarodowa",
        "MINIMAL_NATIONAL_EXPECTED_MARGIN": "Minimalna oczekiwana marża krajowa",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Nazwa warunków usługi.",
        "POLL_INTERVAL": "Częstotliwość odświeżania tabeli zleceń (w milisekundach)",
        "RESET_PASSWORD_IS_DISABLED": "Wyłącz konieczność resetowania hasła.",
        "SAP_COMMUNICATION_ENABLED": "Włącz komunikację z SAP",
        "SCHEDULER_POLLING_FREQUENCY": "Częstotliwość odświeżania harmonogramu (w milisekundach)",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "Parametr określający czy pole akceptacji warunków usługi ma być widoczne w formularzu awizacji.",
        "STATUS_POLL_INTERVAL": "Częstotliwość odpytywania o status lokalizatora (w minutach)",
        "WASHING_TIME_IN_HOURS": "Czas mycia (w godzinach)",
        "XLS_TOP_ROW_ENABLED": "Włącz nagłówek dla raportów XLS."
      },
      "edit": "Edytuj ustawienia",
      "emails": {
        "label": "Powiadomienia e-mail",
        "lateDocuments": "opóźnienie w dodawaniu dokumentów"
      },
      "label": "Ustawienia",
      "serviceTypes": {
        "desc": "Typ serwisu",
        "label": "Edycja typów serwisowych",
        "tableHeaderLabel": "Typ serwisu"
      },
      "transportTypes": {
        "header": "Ustawienia typu transportu",
        "label": "Ustawienia typów transportu",
        "permissions": "Uprawnienia",
        "permissionsSaveSuccess": "Poprawnie zapisaliśmy ustawienia uprawnień.",
        "workflow": "Etapy i statusy"
      },
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany ustawień"
    },
    "statuses": {
      "deleted": "Usunięte",
      "finished": "Zakończone",
      "inProgress": "W trakcie",
      "initial": "Zaawizowane",
      "planned": "Zaplanowane",
      "ready": "Zakończone",
      "toPlan": "Do zaplanowania",
      "waitingForConfirmation": "Oczekuje na potwierdzenie"
    },
    "statute": {
      "accept": {
        "disabled.title": "Proszę zaznaczyć oświadczenie akceptacji regulaminu.",
        "header": "Drogi użytkowniku",
        "inTouch": "Rozumiem, będziemy w kontakcie!",
        "informed": "Rozumiem, czuję się poinformowany.",
        "label": "Rozumiem, wyrażam na to zgodę.",
        "manual": "Przeczytałem, rozumiem i akceptuję Instrukcję bezpieczeństwa.",
        "paragraph_1": "1. LOGINTEGRA Spółka z ograniczoną odpowiedzialnością z siedzibą w Gdańsku, ul. Lęborska 3b, wpisaną do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy Gdańsk - Północ w Gdańsku, VII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000426238, NIP 584-272-80-64, REGON 221707056 jest administratorem Twoich danych osobowych, o których mowa w RODO, znajdujących się w tym Systemie. Dane kontaktowe administratora są następujące: tel.: +48 58 535 78 06, e-mail: info@logintegra.com. Dane te mogą być udostępnione innym użytkownikom zaproszonym do Systemu lub tym, od których zaproszenie zaakceptowali Państwo, a także innym podmiotom opisanym w poniższej informacji. ",
        "paragraph_10": "10. W przypadku jakichkolwiek pytań, wątpliwości lub prośby dotyczące przetwarzania Twoich danych, najłatwiej kontaktować się z nami pisząc na adres e-mail: info@logintegra.com",
        "paragraph_2": "2. Twoje Dane osobowe będą przetwarzane, wyłącznie w celu wykonania umów zawartych pomiędzy Administratorem i przetwarzającymi, realizacji obowiązków i praw wiążących się z zawartymi umowami, realizacji poszczególnych funkcjonalności Systemu, oraz w celu realizacji obowiązków wynikających z przepisów prawa, a także w zakresie w jakim wyrazisz zgodę na przetwarzanie Twoich danych. ",
        "paragraph_3": "3. System, którego jesteś użytkownikiem służy do współpracy pomiędzy przedsiębiorstwami Użytkowników w ramach obsługi procesów logistycznych Administratora, w związku z tym dane osobowe mogą być widoczne dla innych Użytkowników Systemu lub mogą być im wysyłane lub przekazywane. Twoje dane osobowe mogą również być przekazywane w innych przypadkach, przewidzianych przepisami prawa, w szczególności: ",
        "paragraph_3_1": "organom administracji skarbowej - w zakresie w jakim przekazanie danych osobowych stanowi obowiązek wynikający z przepisów podatkowych;",
        "paragraph_3_2": "organom administracji publicznej, w tym m.in. policji, sądom, prokuraturze, organom realizującym zadania z zakresu ubezpieczeń społecznych, w zakresie w jakim przekazanie danych osobowych stanowi obowiązek wynikający z przepisów prawa,",
        "paragraph_3_3": "zaufanym podwykonawcom, którzy wykonują w naszym imieniu obsługę pomocy technicznej lub świadczą usługi hostingowe;",
        "paragraph_3_4": "podmiotom świadczącym usługi księgowe, podatkowe oraz prawne",
        "paragraph_4": "4. Twoje dane osobowe będą przetwarzane przez okres niezbędny dla realizacji celów określonych w pkt 2 lub do czasu wycofania zgody. Niektóre kategorie danych mogą być przechowywane dłużej, jeśli obowiązek taki wynika z przepisów prawa, np. dane zawarte w wystawionych fakturach przechowywane będą przez administratora do czasu upływu ustawowych terminów przechowywania faktur dla celów podatkowych.",
        "paragraph_5": "5. Podanie przez Ciebie danych osobowych jest dobrowolne, ale korzystanie z systemu, z uwagi na jego specyfikę, wymaga podania pewnych kategorii danych. Jeśli nie podasz prawdziwych danych osobowych wymaganych w formularzu rejestracyjnym lub wycofasz zgodę na ich przetwarzanie, użytwanie Systemu nie będzie możliwe.",
        "paragraph_6": "6. Masz prawo do:",
        "paragraph_6_1": "dostępu do treści swoich danych;",
        "paragraph_6_2": "sprostowania swoich danych,",
        "paragraph_6_3": "usunięcia lub ograniczenia przetwarzania swoich danych,",
        "paragraph_6_4": "wniesienia sprzeciwu wobec przetwarzania swoich danych",
        "paragraph_6_5": "przenoszenia danych;",
        "paragraph_6_6": "cofnięcia zgody na przetwarzanie danych w dowolnym momencie, które pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody, przed jej cofnięciem.",
        "paragraph_6_bottom": "Ponadto masz również prawo do wniesienia skargi do organu nadzorczego, gdy uznasz, że przetwarzanie Twoich danych osobowych narusza przepisy prawa. ",
        "paragraph_7": "7. Szczegółowe kwestie związane z ochroną danych są regulowane w naszych dokumentach:",
        "paragraph_7_1": "Polityce przetwarzania danych;",
        "paragraph_7_2": "Instrukcji bezpieczeństwa,",
        "paragraph_7_3": "Regulaminie używania Systemu.",
        "paragraph_7_bottom": "Aby uzyskać dostęp do dokumentów, kliknij na ich nazwę.",
        "paragraph_8": "8. Nasz System używa plików cookies (ciasteczek).",
        "paragraph_9": "9. Pamiętaj, że w systemie przetwarzane są dane osobowe. Wprowadzając do systemu dane osób trzecich. np. kierowców musisz zadbać o spełnienie obowiązków ciążących na administratorze danych osobowych określonych w RODO. Obowiązki te obejmują między innymi poinformowanie osoby, której dane są przetwarzane o:",
        "paragraph_9_1": "tożsamości administratora i jego danych kontaktowych;",
        "paragraph_9_2": "celach przetwarzania danych osobowych, oraz podstawie prawnej przetwarzania;",
        "paragraph_9_3": "informacjach o odbiorcach danych osobowych lub o kategoriach odbiorców, jeżeli istnieją;",
        "paragraph_9_4": "okresie, przez który dane osobowe będą przechowywane;",
        "paragraph_9_5": "o prawie do żądania od administratora dostępu do danych osobowych dotyczących osoby, której dane dotyczą, ich sprostowania, usunięcia lub ograniczenia przetwarzania lub o prawie do wniesienia sprzeciwu wobec przetwarzania, a także o prawie do przenoszenia danych;",
        "paragraph_9_6": "jeżeli przetwarzanie odbywa się na podstawie zgody osoby - informacji o prawie do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;",
        "paragraph_9_7": "prawie wniesienia skargi do organu nadzorczego;",
        "paragraph_9_8": "tym, czy podanie danych osobowych jest wymogiem ustawowym lub umownym lub warunkiem zawarcia umowy oraz czy osoba, której dane dotyczą, jest zobowiązana do ich podania i jakie są ewentualne konsekwencje niepodania danych.",
        "paragraph_9_bottom": "Pamiętaj, aby zadbać o spełnienie obowiązków administratora. Jeśli dowiesz się o możliwym naruszeniu zasad przetwarzania danych osobowych - niezwłocznie nas poinformuj.",
        "policy": "Przeczytałem, rozumiem i akceptuję Politykę przetwarzania danych.",
        "statute": "Przeczytałem, rozumiem i akceptuję Regulamin używania Systemu",
        "subHeader": "W związku z tym, że wchodzą w życie przepisy rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), opublikowanego w dzienniku urzędowym Unii Europejskiej seria L z 2016 r. Nr 119, str. 1. Dla zachowania przejrzystości treści będziemy to  rozporządzenie nazywać skrótowo RODO. Wykonując obowiązki nałożone przez RODO na administratorów i przetwarzających dane osobowe, chcielibyśmy poinformować Cię o kilku istotnych kwestiach z tym związanych. Prosimy o uważne zapoznanie się z poniższą informacją:"
      },
      "label": "Regulamin",
      "label.ablative": "Regulaminem",
      "reject": {
        "button": "Rezygnuję (konto zostanie zablokowane)",
        "confirmation": "Jesteś pewien? Twoje konto zostanie zablokowane."
      }
    },
    "step": {
      "errors": {
        "address": {
          "blank": "Proszę podać adres"
        },
        "company": {
          "blank": "Proszę podać klienta"
        },
        "deliveryDateFrom": {
          "blank": "Proszę podać datę załadunku / rozładunku",
          "blankLeaveFrom": "Proszę podać datę rozpoczęcia urlopu",
          "blankServiceFrom": "Proszę podać datę przyjazdu do serwisu"
        },
        "deliveryDateTo": {
          "blankLeaveFrom": "Proszę podać datę rozpoczęcia urlopu",
          "blankLeaveTo": "Proszę podać datę zakończenia urlopu",
          "blankServiceTo": "Proszę podać datę odebrania pojazdu z serwisu",
          "wrongTimeWindow": "Niepoprawne okno czasowe: koniec załadunku/rozładunku musi mieć miejsce po rozpoczęciu załadunku/rozładunku"
        },
        "distance": {
          "incorrectNumber": "Proszę podać poprawny dystans"
        }
      },
      "exportClearance": "Odprawa celna eksport",
      "from": "Z:",
      "importClearance": "Odprawa celna import",
      "load": {
        "date": "Data załadunku"
      },
      "to": "Do:",
      "unload": {
        "date": "Data rozładunku"
      }
    },
    "table": {
      "errorInfo": "Coś poszło nie tak, sprawdź zakładkę integracja",
      "errors": {
        "alreadyAssigned": "Nie można zmienić przypisania zasobów do trasy",
        "conflicts": "Wystąpiły konflikty, wskazana edycja transportu",
        "generic": "Wystąpił błąd.",
        "routeStatus": "Nie można zmienić powiązania trasy z zasobami ze względu na status trasy",
        "unexpectedException": "Wystąpił błąd przy tworzeniu trasy w systemie lokalizatorów. Spróbuj ponownie za kilka minut lub skontaktuj się z <a href='mailto:info@logintegra.com'>info@logintegra.com</a>"
      },
      "flAutomaticTimeShiftInfo": "Automatyczne przesunięcie terminu transportu w AddSecure",
      "flTrackerId": "Zlecenie do AddSecure",
      "loadMore": "Załaduj więcej danych",
      "loading": "Ładuję",
      "select": "Wybierz",
      "synchronizationInProgress": "Synchronizacja z AddSecure... Proszę czekać",
      "transports": {
        "showAll": "Wszystkie",
        "showMine": "Moje transporty"
      },
      "waitingForConnection": "Trwa łączenie"
    },
    "tabs": {
      "companies": {
        "CARRIER": "Przewoźnicy",
        "CLIENT": "Klienci",
        "SERVICE": "Serwis",
        "WASH": "Myjnie",
        "label": "Kontrahenci"
      },
      "externalCostOrders": "Zamówienia",
      "invitations": "Zaproszenia",
      "order": "Zlecenie",
      "orders": {
        "archive": "Archiwum",
        "grouping": "Grupowanie",
        "label": "Zlecenia",
        "list": "Lista"
      },
      "plan": "Zaplanuj",
      "resources": {
        "CONTAINER": "Kontenery",
        "DRIVER": "Kierowcy",
        "TRAILER": "Naczepy",
        "TRUCK": "Ciągniki",
        "label": "Zasoby",
        "reviewSchedule": "Plan napraw/przeglądów",
        "reviewScheduleArchive": "Archiwum napraw/przeglądów",
        "scheduleRepairsAndReviews": "Harmonogram napraw/przeglądów"
      },
      "review": "Przegląd",
      "schedule": "Harmonogram",
      "service-inspection": "Naprawa/przegląd",
      "services": "Plan napraw przeglądów",
      "settings": "Ustawienia",
      "users": "Użytkownicy"
    },
    "task": {
      "beforeConfirmation": {
        "colorBeforeWas": "a jego wiersz w tabeli oraz okno czasowe mają kolor",
        "stateBeforeWas": "Przed potwierdzeniem tego etapu transport ma status"
      },
      "checkpointName": "Nazwa etapu",
      "defaultStagePrefix": "Potwierdzony",
      "errors": {
        "checkpointName": {
          "blank": "Proszę podać nazwę etapu."
        },
        "expectedDateIntervalModulo": {
          "incorrectNumber": "Niepoprawna liczba"
        },
        "expectedDateReference": {
          "blank": "Proszę podać referencję."
        },
        "stepType": {
          "blank": "Proszę podać typ akcji."
        }
      },
      "expectedDateMode": {
        "exact": "o godzinie",
        "onDay": "w dniu",
        "reference": "najpóźniej"
      },
      "expectedDateReference": "Referencja",
      "group": {
        "0": "Prace przygotowawcze",
        "1": "Załadunki i rozładunki",
        "2": "Zakończenie procesu"
      },
      "label": "Etap",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Oznaczanie opóźnienia",
          "title": "Kliknij w ikonkę, by okno czasowe otrzymywało czerwoną ramkę po potwierdzeniu tego etapu po czasie."
        },
        "enabled": {
          "label": "Oznaczanie opóźnienia",
          "title": "Okno czasowe otrzyma czerwoną ramkę w przypadku potwierdzenia tego etapu po czasie. Kliknij w ikonkę, by zmienić ustawienia."
        }
      },
      "reference": {
        "after": {
          "0": "",
          "earliestStepStart": "rozp. pierwszego zał./rozł.",
          "executionDate": "wykonaniu usługi",
          "latestStepStop": "zakończ. ostatniego zał./rozł.",
          "stepStart": "rozpoczęciu",
          "stepStop": "zakończeniu",
          "transportCreated": "utworzeniu transportu"
        },
        "before": {
          "earliestStepStart": "rozp. pierwszego zał./rozł.",
          "executionDate": "wykonaniem usługi",
          "latestStepStop": "zakończ. ostatniego zał./rozł.",
          "stepStart": "rozpoczęciem",
          "stepStop": "zakończeniem",
          "transportCreated": "utworzeniem transportu"
        }
      },
      "shouldBeConfirmedBy": "powinien być potw.",
      "sign": "Przed/Po",
      "stageName": "Nazwa statusu",
      "stepType": {
        "load": "załadunku",
        "placeholder": "Typ akcji",
        "service": "serwisu",
        "unload": "rozładunku"
      },
      "tracked": {
        "disabled": {
          "label": "",
          "title": "Oczekiwany czas potw. etapu będzie tylko informacją poglądową - w przypadku niezatwierdzenia etapu o czasie nie zmienimy koloru wiersza ani okna i nie będziemy wysyłać powiadomień o spóźnieniu. Kliknij w ikonkę, by zmienić ustawienia."
        },
        "enabled": {
          "label": "Nadzorowanie punktualności",
          "title": "W przypadku niezatwierdzenia etapu o czasie transport przejdzie w stan 'spóźnienia' - wiersz w tabeli oraz okno czasowe zmienią kolor na czerwony oraz zostaną wysłane maile z informacją o spóźnieniu. Kliknij w ikonkę, by zmienić ustawienia."
        }
      },
      "unit": "Jednostka"
    },
    "tasks": {
      "empty": "Nie ma tu żadnych zadań."
    },
    "trailer": {
      "errors": {
        "axisType": {
          "tooShort": "Rodzaj osi powinien składać się z co najmniej trzech znaków"
        },
        "capacity": {
          "blank": "Proszę podać pojemność"
        },
        "flTrackerId": {
          "notANumber": "Numer musi zawierać wyłącznie cyfry"
        },
        "model": {
          "tooShort": "Model powinien składać się z co najmniej trzech znaków"
        },
        "owner": {
          "blank": "Proszę podać właściciela"
        },
        "registrationNumber": {
          "blank": "Proszę podać numer rejestracyjny",
          "tooLong": "Numer rejestracyjny powinien składać się z maksymalnie 40 znaków",
          "tooShort": "Numer rejestracyjny powinien składać się z co najmniej trzech znaków"
        }
      }
    },
    "transaction": {
      "errors": {
        "carrierCompany": {
          "blank": "Proszę wybrać przewoźnika"
        },
        "currency": {
          "blank": "Proszę podać walutę"
        },
        "currencyOfFreight": {
          "blank": "Proszę podać walutę"
        },
        "currencyOfWash": {
          "blank": "Proszę podać walutę"
        },
        "distanceToTheWash": {
          "blank": "Proszę podać odległość w km"
        },
        "kilometersWithLoad": {
          "blank": "Proszę podać kilometry ładowne"
        },
        "priceOfFreight": {
          "incorrectNumber": "Proszę podać poprawną kwotę frachtu"
        },
        "priceOfService": {
          "incorrectNumber": "Proszę podać poprawną cenę usługi"
        },
        "priceOfWash": {
          "incorrectNumber": "Proszę podać poprawny koszt mycia"
        }
      },
      "profitMargin": "Marża"
    },
    "transport": {
      "addNewCompany": {
        "assignmentCompany": "Chcesz przypisać tę firmę jako wykonawcę transportu?",
        "cooperatingCompanies": "Współpracujesz już z firmą o podobnej nazwie",
        "emailInfo": "Na podany adres e-mail zostanie wysłane zaproszenie do współpracy.",
        "emailRequired": "Jeśli chcesz wysłać zaproszenie do współpracy musisz podać adres email",
        "existingCompany": "Posiadamy już w naszej bazie firmę o NIP-ie",
        "existingCompanyAndPartnership": "Firma o numerze NIP {taxIdNumber} jest już zarejestrowana w systemie i należy do grupy Twoich współpracowników",
        "label": "Dodaj firmę, której nie ma w systemie.",
        "sendInvitation": "Wyślij zaproszenie do współpracy",
        "wrongEmail": "Podany adres email jest błędny"
      },
      "additionalData": "Dodatkowe dane",
      "additionalDataPlaceholder": "Dodatkowe uwagi widoczne na dokumencie zlecenia (pdf)",
      "advice": "Awizuj",
      "adviceFor": "Nazwa firmy",
      "arrivalDate": "Data przybycia",
      "bigQuantityWarning": "Czy jesteś pewien, że awizujesz więcej niż jedną naczepę towaru?",
      "canNotEditAfterProcessStarts": "Możesz edytować tylko dane awizacji, które utworzyłeś oraz przed wjazdem kierowcy na teren zakładu.",
      "canNotEditBecauseOfOtherContractors": "Nie możesz edytować danych awizacji od wielu kontrahentów.",
      "carRegistrationNumber": "Numer rejestracyjny ciągnika",
      "carrier": {
        "chooseCarrier": "Wybierz przewoźnika",
        "kilometersWithLoad": "Kilometry ładowne",
        "label": "Przewoźnik",
        "pleaseChooseCarrier": "Proszę wybrać przewoźnika",
        "priceLowerThanMinimalMargin": "Ustalona marża jest niższa od minimalnej dopuszczalnej marży, wynoszącej {minimalProfitMargin} {currencySymbol}",
        "priceLowerThanMinimalMarginAccept": "Czy jesteś pewien, że chcesz przekazać zlecenie z podaną ceną frachtu?",
        "priceOfFreight": "Fracht"
      },
      "carrierCompany": "Przewoźnik",
      "changeCarrierConfirm": "Czy na pewno chcesz zmienić przewoźnika przypisanego do transportu? W przypadku zmiany przewoźnika w transporcie, w którym została już dodana awizacja dodatkowo w transporcie zajdą nieodwracalne zmiany:\n- zostaną wyczyszczone dane awizacji\na po zapisaniu formularza:\n- zostaną usunięte wszystkie zamówienia na usługę zewnętrzną,\n- zostaną wyczyszczone dane związane z powiadomieniami w sekcji Powiadomienia,\n- zostanie usunięta wybrana do zlecenia myjnia (o ile jest w zleceniu),\n- zostanie usunięty etap 'mycie' z listy etapów do potwierdzenia (o ile był w zleceniu).\nUwaga! W przypadku zamówień na usługę zewnętrzną potwierdzonych już w SAP konieczne będzie usunięcie zamówień po stronie SAP.",
      "checkpoints": {
        "confirmed": "Potwierdził",
        "label": "Statusy",
        "planConfirm": "Planowana data potwierdzenia",
        "realConfirm": "Rzeczywista data potwierdzenia",
        "status": "Status"
      },
      "chooseForwarder": "Wybierz spedytora",
      "client": {
        "confirm": "Potwierdź",
        "label": "Klient"
      },
      "clientCompany": "Klient",
      "confirmBefore": "Przed wybraniem potwierdź zlecenie",
      "contractors": {
        "add": {
          "title": "Dodaj kolejnego kontrahenta"
        },
        "delete": {
          "title": "Usuń kontrahenta z tego transportu"
        }
      },
      "createTitle": "Zaplanuj dostawę",
      "createdBy": "Utworzony przez",
      "createdMessage": "Utworzony {date} przez {person} ({company})",
      "currency": "Waluta",
      "dateOfAdvice": "Data dostawy",
      "dateOfArchiving": "Data przeniesienia do archiwum",
      "dateOfCollection": "Data odbioru",
      "dateOfDeletion": "Data usunięcia",
      "deleteTransport": "Czy na pewno chcesz usunąć transport?",
      "description": "Uwagi",
      "details": "Szczegóły dostawy",
      "documentNumber": "Numer dokumentu kierowcy",
      "documents": {
        "empty": "Do tej dostawy nie zostały jeszcze dodane dokumenty.",
        "header": "Dokumenty dla transportu {identifier}",
        "label": "Dokumenty"
      },
      "downloadAsPDF": "Pobierz dokument zlecenia w formacie PDF",
      "driverIdentifier": "Numer dowodu osobistego",
      "driverLanguage": "Język kierowcy",
      "driverMobilePhoneNumber": "Numer telefonu kierowcy",
      "driverName": "Nazwisko i imię kierowcy",
      "earliestStepStart": "Data załadunku",
      "erpIdentifier": "Numer zlecenia klienta",
      "errors": {
        "clientCompany": {
          "blank": "Proszę podać kienta"
        },
        "contractors": {
          "notUnique": "Proszę podać unikalną listę kontrahentów"
        },
        "dateOfAdvice": {
          "blank": "Proszę podać datę dostawy",
          "isInPast": "Nie możesz zaplanować dostawy w przeszłości"
        },
        "erpIdentifier": {
          "blank": "Proszę podać numer zlecenia klienta"
        },
        "forwarder": {
          "blank": "Proszę wybrać spedytora"
        },
        "requiredCarType": {
          "blank": "Proszę podać wymagane auto"
        },
        "summaryQuantity": {
          "nonPositiveInteger": "Ilość palet musi być liczbą całkowitą większą niż  0"
        },
        "summaryQuantityBoxes": {
          "nonPositiveInteger": "Ilość kartonów musi być liczbą całkowitą większą niż 0",
          "summaryQuantityBlank": "Oszacuj na ilu paletach zmieszczą się zaznaczone kartony i podaj ilość - minimum 1 paleta"
        },
        "wash": {
          "blank": "Proszę wybrać myjnię"
        }
      },
      "filterDate": {
        "max": {
          "placeholder": {
            "executionDate": "Maks. data wyk. usł.",
            "transportDate": "Maks. data"
          },
          "title": {
            "executionDate": "Wybierz maksymalną datę wykonania usługi.",
            "transportDate": "Wybierz maksymalną datę."
          }
        },
        "min": {
          "placeholder": {
            "executionDate": "Min. data wyk. usł.",
            "transportDate": "Min. data"
          },
          "title": {
            "executionDate": "Wybierz minimalna datę wykonania usługi",
            "transportDate": "Wybierz minimalną datę."
          }
        },
        "mode": {
          "LOAD_DATE": "Załadunku",
          "UNLOAD_DATE": "Rozładunku"
        }
      },
      "forwarder": "Spedytor",
      "group": "Grupa",
      "grouping": {
        "INTERMODAL": "Transport tankkontenerami",
        "INTERNATIONAL": "Transport cysternami poza Polską",
        "NATIONAL": "Transport cysternami na terenie Polski",
        "assignedRoutes": "Przydzielone trasy:",
        "dropHere": "Upuść tutaj trasę...",
        "forwarders": "Spedytorzy:",
        "forwardersGroup": "Grupy spedytorów",
        "notAssignedRoutes": "Nieprzydzielone trasy",
        "responsibleFor": "Odpowiedzialni za:"
      },
      "integration": {
        "controllingNumber": "Numer zlecenia kontrolingowego",
        "dateCreated": "Data utworzenia",
        "flOrderNumber": "Numer trasy",
        "label": "Integracja",
        "message": "Komunikat",
        "messages": {
          "ASSIGNED_RESOURCE_OCCUPANCY": "Dodanie powiązania trasy z zasobami",
          "AUTOMATIC_TIME_SHIFT": "Automatyczne przesunięcie terminu transportu",
          "CREATED_CONTROLLING_ORDER": "Utworzenie zl. kontrol.",
          "CREATED_FRAMELOGIC_ROUTE": "Utworzenie trasy",
          "UPDATED_CONTROLLING_ORDER": "Edycja zl. kontrol.",
          "UPDATED_FRAMELOGIC_ROUTE": "Edycja powiązania trasy z zasobami"
        },
        "responseValue": "Odpowiedź"
      },
      "latestStepStop": "Data Rozładunku",
      "load": "Załadunek",
      "load-address": "Adres (załadunek)",
      "load-company": "Firma (załadunek)",
      "load-deliveryDateFrom": "Data \"od\" (załadunek)",
      "load-deliveryDateTo": "Data \"do\" (załadunek)",
      "load-distance": "Dystans (załadunek)",
      "load-documentIdentifier": "Numer dokumentu (załadunek)",
      "load-exportClearance": "Odprawa eksport (załadunek)",
      "load-importClearance": "Odprawa import (załadunek)",
      "load-quantity": "Ilość (załadunek)",
      "load-volume": "Objętość (załadunek)",
      "load-weight": "Waga (załadunek)",
      "logs": {
        "label": "Historia zmian"
      },
      "noFilesForOldBrowsers": "Funkcjonalność dodawania plików w tym widoku nie jest dostępna na Twojej przeglądarce. Plik można dodać z poziomu tabeli.",
      "otherRequirements": "Inne wymagania",
      "packs": {
        "boxes": "Kartony",
        "label": "Opakowania",
        "pallets": "Palety"
      },
      "personsGroup": "Grupa",
      "plan": "Zaplanuj",
      "priceOfService": "Cena usługi",
      "products": {
        "label": "Ładunek",
        "name": "Nazwa",
        "symbol": "Symbol",
        "totalGrossTonnage": "Waga brutto",
        "totalNetTonnage": "Waga netto"
      },
      "quantity": "Ilość jednostek ładunkowych",
      "remoteIdHeader": "Id w systemie klienta",
      "requiredCarType": "Wymagane auto",
      "saveTransportConfirm": "Zmieniłeś dane załadunku lub rozładunku. Czy na pewno chcesz zapisać transport z podanym frachtem / kilometrami ładownymi?",
      "selectCountry": "Kraj",
      "selectSupplier": "Wybierz dostawcę \\\\ odbiorcę",
      "selectTransportType": "Wybierz rodzaj dostawy",
      "service-address": "Adres (serwis)",
      "service-company": "Firma (serwis)",
      "service-deliveryDateFrom": "Data \"od\" (serwis)",
      "service-deliveryDateTo": "Data \"do\" (serwis)",
      "service-distance": "Dystans (serwis)",
      "service-documentIdentifier": "Numer dokumentu (serwis)",
      "service-exportClearance": "Odprawa eksport (serwis)",
      "service-importClearance": "Odprawa import (serwis)",
      "service-quantity": "Ilość (serwis)",
      "service-volume": "Objętość (serwis)",
      "service-weight": "Waga (serwis)",
      "stoNumberHeader": "Numer STO",
      "syncCheckpoints": "Wykonaj synchronizację statusów ze zleceniem {remoteId} na Platformie PCC",
      "syncWithRemote": "Wykonaj synchronizację ze zleceniem {remoteId} na Platformie PCC",
      "termsOfConditionsAccepted": "Akceptuję",
      "title": "Dostawa {ident}",
      "tradesman": {
        "label": "Handlowiec"
      },
      "trailerRegistrationNumber": "Numer rejestracyjny naczepy",
      "type": {
        "DELIVERY": "Dostawa dla PCC",
        "EXTERNAL_COST_ORDER": "Zamówienie",
        "EXTERNAL_DELIVERY": "Dostawa zewnętrzna dla PCC",
        "EXTERNAL_MANUAL_TRANSPORT": "Transport zewnętrzny",
        "EXTERNAL_TRANSPORT_ORDER": "Transport zewnętrzny dla PCC",
        "LEAVE": "Urlop",
        "MANUAL_TRANSPORT": "Transport",
        "SERVICE": "Naprawa/Przegląd",
        "TRANSPORT": "Transport dla PCC",
        "TRANSPORT_ORDER": "Zlecenie transportowe"
      },
      "unload": "Rozładunek",
      "unload-address": "Adres (rozładunek)",
      "unload-company": "Firma (rozładunek)",
      "unload-deliveryDateFrom": "Data \"od\" (rozładunek)",
      "unload-deliveryDateTo": "Data \"do\" (rozładunek)",
      "unload-distance": "Dystans (rozładunek)",
      "unload-documentIdentifier": "Numer dokumentu (rozładunek)",
      "unload-exportClearance": "Odprawa eksport (rozładunek)",
      "unload-importClearance": "Odprawa import (rozładunek)",
      "unload-quantity": "Ilość (rozładunek)",
      "unload-volume": "Objętość (rozładunek)",
      "unload-weight": "Waga (rozładunek)",
      "volume": "Objętość",
      "wash": {
        "chooseWash": "Wybierz myjnię",
        "choosingWashFailedAlert": "Wybieranie myjni nie powiodło się. Prosimy spróbować ponownie, a jeśli problem nie ustępuje, prosimy o kontakt z działem technicznym.",
        "costOfWashing": "Koszt mycia",
        "distanceInKm": "Odległość w km",
        "label": "Myjnia"
      },
      "wash-address": "Adres (myjnia)",
      "wash-company": "Firma (myjnia)",
      "wash-deliveryDateFrom": "Data \"od\" (myjnia)",
      "wash-deliveryDateTo": "Data \"do\" (myjnia)",
      "wash-distance": "Dystans (myjnia)",
      "wash-documentIdentifier": "Numer dokumentu (myjnia)",
      "wash-exportClearance": "Odprawa eksport (myjnia)",
      "wash-importClearance": "Odprawa import (myjnia)",
      "wash-quantity": "Ilość (myjnia)",
      "wash-volume": "Objętość (myjnia)",
      "wash-weight": "Waga (myjnia)",
      "weight": "Waga",
      "wzDocument": "Numery dok. WZ"
    },
    "truck": {
      "errors": {
        "carTypeGroup": {
          "blank": "Proszę wybrać środek transportu"
        },
        "euroStandard": {
          "blank": "Proszę podać normę euro"
        },
        "flTrackerId": {
          "notANumber": "Numer musi zawierać wyłącznie cyfry"
        },
        "group": {
          "chooseGroup": "Proszę wybrać grupę"
        },
        "model": {
          "tooShort": "Model powinien składać się z co najmniej trzech znaków"
        },
        "owner": {
          "blank": "Proszę podać właściciela"
        },
        "registrationNumber": {
          "blank": "Proszę podać numer rejestracyjny",
          "tooLong": "Numer rejestracyjny powinien składać się z maksymalnie 20 znaków",
          "tooShort": "Numer rejestracyjny powinien składać się z co najmniej trzech znaków"
        },
        "yearOfProduction": {
          "notANumber": "Rocznik powinien składać się tylko z liczb z przedziału 1920-rok bieżący"
        }
      }
    },
    "user": {
      "accountCreatedBy": "Konto utworzone przez",
      "activeNotifications": "Aktywne powiadomienia",
      "createUser": "Dodaj użytkownika",
      "deleteConfirm": "Na pewno chcesz usunąć użytkownika?",
      "deletedMessage": "Usunięty {date} przez użytkownika {person} z firmy {company}.",
      "editUser": "Edycja użytkownika",
      "email": "Email",
      "groupName": "Grupa",
      "invitedBy": "Zaproszony: {date} przez {person}.",
      "list": "Lista użytkowników",
      "mobilePhoneNumber": "Telefon",
      "myAccount": "Moje konto",
      "name": "Imię i nazwisko",
      "noActiveNotifications": "Brak aktywnych powiadomień",
      "roleDescription": "Opis roli",
      "sapLogin": "Login SAP",
      "selectRole": "Wybierz rolę",
      "username": "Login"
    },
    "users": {
      "label": "Użytkownicy"
    },
    "vehicle": {
      "add": {
        "CISTERN": "Dodaj cysternę",
        "UNDERCARRIAGE": "Dodaj podwozie"
      },
      "associatedContainer": "Kontener",
      "axisType": "Rodzaj osi",
      "bulwarks": "Falochrony",
      "capacity": "Pojemność",
      "carType": "Typ pojazdu",
      "carTypeGroup": "Środek transportu",
      "card": {
        "CONTAINER": "Karta kontenera",
        "TRAILER": "Karta naczepy",
        "TRUCK": "Karta ciągnika"
      },
      "closestReview": "Najbliższy przegląd/naprawa",
      "containerNumber": "Numer kontenera",
      "containersTrailer": "Podwozie",
      "controllingNumber": "Zlecenie kontrolingowe",
      "costObject": "Obiekt kosztowy",
      "create": {
        "CISTERN": "Utwórz cysternę",
        "CONTAINER": "Kontener",
        "TRUCK": "Utwórz ciągnik",
        "UNDERCARRIAGE": "Utwórz podwozie"
      },
      "deleteConfirm": "Na pewno chcesz usunąć pojazd?",
      "deletedMessage": "Poprawnie usunęliśmy pojazd {vehicleRegistrationNumber}.",
      "description": "Opis",
      "downAirConnection": "Podłączenie powietrza na dole",
      "driver": "Kierowca",
      "euroStandard": "Norma Euro",
      "flTrackerId": "ID (lokalizator)",
      "group": "Grupa",
      "label": {
        "CARRIER": "Przewoźnik",
        "CONTAINER": "Kontener",
        "TRAILER": "Naczepa",
        "TRUCK": "Ciągnik"
      },
      "model": "Model",
      "mpk": "MPK",
      "numberOfCompartments": "Ilość komór",
      "numberOfManholes": "Ilość włazów",
      "outOfCommission": "Sprzęt wyłączony z eksploatacji",
      "outOfCommissionHint": "Sprzęt wyłączony z eksploatacji",
      "owner": "Właściciel",
      "plural": {
        "CISTERN": "cysterny",
        "CONTAINER": "kontenery",
        "TRAILER": "naczepy",
        "TRUCK": "ciągniki",
        "UNDERCARRIAGE": "podwozia",
        "VEHICLE": "pojazdy"
      },
      "registrationNumber": "Numer rejestracyjny",
      "route": "Trasa",
      "savedMessage": "Poprawnie zapisaliśmy pojazd {vehicleRegistrationNumber}.",
      "scheduleReview": "Zaplanuj",
      "sideNumber": "Numer boczny",
      "trailer": "Naczepa",
      "trailerType": {
        "CISTERN": "Cysterna",
        "UNDERCARRIAGE": "Podwozie",
        "label": "Typ"
      },
      "truck": "Ciągnik",
      "validityDates": "Daty ważności",
      "valveType": "Typ zaworu",
      "yearOfProduction": "Rocznik"
    },
    "warehouse": {
      "reserveFreePlace": "Zarezerwuj wolne miejsce w harmonogramie na {initialStart}"
    },
    "washingStatus": {
      "warning": "Naczepa ma niepotwierdzony status mycia w co najmniej dwóch zleceniach. Przed wyborem naczepy do awizacji może być maksymalnie jedno zlecenie bez potwierdzonego statusu mycia."
    },
    "workflows": {
      "addTask": "Dodaj etap",
      "label": "Ustawienia etapów i statusów",
      "saveSuccessMessage": "Poprawnie zapisaliśmy ustawienia etapów i statusów.",
      "updateDisclaimer": "Uwaga: zmiany dotyczące czasu zaczną obowiązywać tylko dla NOWYCH transportów."
    },
    "wrongUrl": "Brak dostępu lub strona o podanym adresie nie istnieje",
    "xls": {
      "mode": {
        "ROUTES": {
          "desc": "Raport zawiera wykonane trasy przez kierowców. Pod uwagę brane są zlecenia, w których istnieje przynajmniej jeden rozładunek, którego faktyczna data potwierdzenia lub planowana data rozładunku (w przypadku gdy nie ma potwierdzonego etapu) znajduje się w zadanym zakresie czasu.",
          "disabled": "Raport wykonanych tras dostępny jest tylko w przypadku filtrowania zleceń po dacie rozładunku.",
          "label": "Raport wykonanych tras"
        },
        "TRANSPORTS": {
          "desc": "Raport zleceń zawiera szczegóły wybranych zleceń.",
          "label": "Raport zleceń"
        },
        "select": "Wybierz wersję pliku"
      },
      "title": "Parametry pliku Excel"
    }
  };
  _exports.default = _default;
});