define("apollo/pods/companies/create/controller", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/attachment-mixin", "apollo/mixins/modal-mixin", "apollo/mixins/transports-mixin"], function (_exports, _jquery, _environment, _attachmentMixin, _modalMixin, _transportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_attachmentMixin.default, _modalMixin.default, _transportsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),
    companiesIndexController: Ember.inject.controller('companies/index'),
    isEditing: false,
    TRANSPORT_TYPES: Ember.computed('', function () {
      return this.get('store').peekAll('transport-type').filter(function (type) {
        return ["TRANSPORT", "EXTERNAL_TRANSPORT_ORDER", "MANUAL_TRANSPORT", "EXTERNAL_MANUAL_TRANSPORT", "DELIVERY", "EXTERNAL_DELIVERY"].includes(type.get('name'));
      });
    }),
    loadingInProgress: false,
    mapImage: Ember.computed(function () {
      return '/assets/images/sample/map.png';
    }).readOnly(),
    hasDirtyDocuments: Ember.computed('model.company.files.@each.hasDirtyAttributes', function () {
      return this.get('model.company.files').filter(function (f) {
        return f.get('hasDirtyAttributes');
      }).length > 0;
    }),
    canNotEditCompanyMainSection: Ember.computed(function () {
      return this.get('sessionAccount.currentUser.canNotEditCompany');
    }),
    isDirty: Ember.computed('model.company.hasDirtyAttributes', 'model.company.address.hasDirtyAttributes', 'model.company.partnershipWithPrincipal.hasDirtyAttributes', 'hasDirtyDocuments', 'attachments.length', function () {
      return this.get('model.company.hasDirtyAttributes') || this.get('model.company.address.hasDirtyAttributes') || this.get('model.company.files.hasDirtyAttributes') || this.get('model.company.partnershipWithPrincipal.hasDirtyAttributes') || this.get('hasDirtyDocuments') || this.get('attachments.length');
    }),
    setCompanyCountry: Ember.observer('model.company.address.country', function () {
      var addressCountry = this.get('model.company.address.country');
      this.get('model.company').set('country', addressCountry);
    }),
    errors: [],
    showOrderHistory: false,
    showResourcesHistory: false,
    historyLogs: [],
    setHistoryLogs: Ember.observer('showResourcesHistory', function () {
      var auditLog = this.get('model.company.auditLogs');
      this.set('historyLogs', auditLog);
    }),
    principal: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    setTransportType: Ember.observer('principal.transportTypes.firstObject', 'showOrderHistory', function () {
      this.set('transportType', this.get('principal.transportTypes.firstObject'));
    }),
    searchParams: Ember.computed('model', function () {
      if (this.get('model.company.companyTypeName') === 'CARRIER') {
        return {
          query: '',
          carrierId: this.get('model.company.id'),
          filters: Ember.A(),
          assetType: '',
          className: 'Company'
        };
      }

      if (this.get('model.company.companyTypeName') === 'CLIENT') {
        return {
          query: '',
          clientId: this.get('model.company.id'),
          filters: Ember.A(),
          assetType: '',
          className: 'Company'
        };
      }

      if (this.get('model.company.companyTypeName') === 'WASH') {
        return {
          query: '',
          washId: this.get('model.company.id'),
          filters: Ember.A(),
          assetType: '',
          className: 'Company'
        };
      }

      return {
        query: '',
        id: this.get('model.company.id'),
        filters: Ember.A(),
        assetType: '',
        className: 'Company'
      };
    }),
    drivers: Ember.computed('model.company.drivers.@each.enabled', function () {
      return this.get('model.company.drivers').filterBy('enabled', true);
    }),
    trucks: Ember.computed('model.company.trucks.@each.enabled', function () {
      return this.get('model.company.trucks').filterBy('enabled', true);
    }),
    trailers: Ember.computed('model.company.trailers.@each.enabled', function () {
      return this.get('model.company.trailers').filterBy('enabled', true);
    }),
    actions: {
      save: function save() {
        var self = this;
        var companyModel = this.get('model.company');

        if (!companyModel.get('shortName')) {
          companyModel.set('shortName', companyModel.get('name'));
        }

        if (!companyModel.get('address.recipient')) {
          companyModel.get('address').set('recipient', companyModel.get('name'));
        }

        companyModel.validate();
        companyModel.get('address').validate();
        companyModel.get('partnershipWithPrincipal').validate();

        if (companyModel.get('errors.length') || companyModel.get('address').get('errors.length') || companyModel.get('partnershipWithPrincipal').get('errors.length')) {
          return;
        }

        var addressModel = companyModel.get('address');
        var partnershipModel = companyModel.get('partnershipWithPrincipal'); // Zapisujemy sobie w mapie dodane tymczasowe pliki

        var map = {};
        companyModel.get('resourceDates').forEach(function (r) {
          map[r.get('controlledDateType.name')] = r.get('attachmentsTmp');
        });
        companyModel.save().then(function (company) {
          self.set('errors', []); // czyścimy duplikaty - w tym momencie jezeli dodajemy RD razem z plikiem to ember
          // wysyła jakieś dziwne duplikaty obiektów bez przypisanego ID w którym przetrzymuje pliki tymczasowe

          self.removeDuplicateRecords(companyModel, 'resourceDates');
          var resourceDatesCopy = companyModel.get('resourceDates').slice();
          resourceDatesCopy.forEach(function (rd) {
            if (rd !== undefined && !rd.get('id')) {
              companyModel.get('resourceDates').removeObject(rd);
              rd.unloadRecord();
            }
          });
          var resources = companyModel.get('resourceDates'); // po czyszczeniu duplikatów tracimi informacje o plikach tymczasowych
          // więc musimy je ponownie przypisać

          resources.forEach(function (r) {
            if (map[r.get('controlledDateType.name')]) {
              r.set('attachmentsTmp', map[r.get('controlledDateType.name')]);
            }
          });
          var attachmentsForComapny = companyModel.get('attachmentsTmp');
          var resourceWithAttachments = resources.filter(function (item) {
            return item.attachmentsTmp ? item.attachmentsTmp : null;
          });
          var token = self.get('sessionAccount').get('token');

          if (!Ember.isEmpty(attachmentsForComapny)) {
            self.uploadAttachment(company.id, 'companies', token, company.attachmentsTmp).then(function () {
              console.debug("Poprawnie zapisali\u015Bmy pliki dla firmy ".concat(companyModel.name, "."));
              company.attachmentsTmp.clear();
              companyModel.reload();
            });
          }

          if (!Ember.isEmpty(resourceWithAttachments)) {
            resourceWithAttachments.forEach(function (rwa) {
              self.uploadAttachment(rwa.id, 'resource-dates', token, rwa.attachmentsTmp).then(function () {
                console.debug("Poprawnie zapisali\u015Bmy pliki dla firmy ".concat(companyModel.name, "."));
                rwa.attachmentsTmp.clear();
              });
            });
          }

          var companyEmailsTmp = companyModel.get('companyEmailsTmp').slice();
          companyModel.get('companyEmailsTmp').clear();
          companyEmailsTmp.forEach(function (cet) {
            cet.set('company.id', company.get('id'));
            cet.save();
          });

          if (self.get('isEditing')) {
            addressModel.reload();
            partnershipModel.reload();
            var msg = self.get('intl').t('company.updatedMessage', {
              companyName: companyModel.get('shortName')
            });
            self.get('companiesIndexController').set('successMessage', msg);
            var companyTypeName = partnershipModel.get('partnershipType.name');

            if (self.canNotEditCompanyMainSection) {
              console.log("Poprawnie zapisali\u015Bmy dane firmy.");
            } else {
              self.transitionToRoute('companies.index', companyTypeName);
            }
          } else {
            var _companyTypeName = partnershipModel.get('partnershipType.name'); // Po zapisaniu firmy, z serwera przychodzi lista adresów i partnerstw z numerami id, ale
            // store tworzy dla nich nowe modele zamiast nadpisać addressModel i partnershipModel.
            // Po usunięciu tych dwóch modeli, do firmy zostaną tylko rekordy, które przyszły w odpowiedzi z serwera.


            addressModel.unloadRecord();
            partnershipModel.unloadRecord();

            var _msg = self.get('intl').t('company.savedMessage', {
              companyName: companyModel.get('shortName')
            });

            self.get('companiesIndexController').set('successMessage', _msg);
            self.transitionToRoute('companies.index', _companyTypeName);
          }
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var companyTypeName = this.get('model.company.companyTypeName');
        this.get('model.company').rollbackAttributes();

        if (this.get('model.company.address')) {
          this.get('model.company.address').rollbackAttributes();
        }

        if (this.get('model.company.partnershipWithPrincipal')) {
          this.get('model.company.partnershipWithPrincipal').rollbackAttributes();
        }

        if (this.get('model.company.files.length')) {
          this.get('model.company.files').forEach(function (f) {
            f.rollbackAttributes();
          });
        }

        this.transitionToRoute('companies.index', companyTypeName);
      },
      disable: function disable() {
        var _this = this;

        var self = this;
        var companyModel = this.get('model.company');
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/companies/' + companyModel.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          var companyTypeName = _this.get('model.company.companyTypeName');

          companyModel.set('enabled', false);
          var msg = self.get('intl').t('company.deletedMessage', {
            companyName: companyModel.get('shortName')
          });
          self.get('companiesIndexController').set('successMessage', msg);
          console.log("Poprawnie usun\u0119li\u015Bmy firm\u0119 ".concat(companyModel.get('name'), "."));
          self.transitionToRoute('companies.index', companyTypeName);
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      toogleOrderHistoryAndClearFilters: function toogleOrderHistoryAndClearFilters() {
        this.set('showResourcesHistory', false);
        this.set('showOrderHistory', !this.get('showOrderHistory'));
        this.set('searchParams.assetType', '');
        this.get('searchParams.filters').clear();
      },
      toogleResourcesHistoryAndClearFilters: function toogleResourcesHistoryAndClearFilters() {
        this.set('searchParams.assetType', 'AUDIT_LOG');
        this.set('showResourcesHistory', !this.get('showResourcesHistory'));
        this.get('searchParams.filters').clear();
      },
      loadingResourcesHistoryData: function loadingResourcesHistoryData() {
        this.set('showOrderHistory', false);
        this.set('loadingInProgress', !this.get('loadingInProgress'));
      },
      disableAddress: function disableAddress(address) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/addresses/' + address.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          address.set('enabled', false);
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      markAddressAsHeadquarter: function markAddressAsHeadquarter(address) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/addresses/' + address.get('id') + '/markAsHeadquarter',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          address.get('company.addresses').map(function (a) {
            a.set('isHeadquarter', false);
          });
          address.set('isHeadquarter', true);
        }, function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});