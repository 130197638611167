define("apollo/mixins/date-columns-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service('session-account'),
    dateColumns: Ember.computed('', function () {
      var settings = this.sessionAccount.resourceDateFormSettings;
      var LAST_COLUMN = 'PCC_MCAA_TRAINING';
      return settings.DRIVER.filter(function (column) {
        return column !== LAST_COLUMN;
      }).concat(settings.DRIVER.includes(LAST_COLUMN) ? [LAST_COLUMN] : []);
    })
  });

  _exports.default = _default;
});